import React from 'react';
import Dashnav from '../../../components/dashnav/dashnav';
import Footer from '../../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class ShipInventoryNow extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: '',
    memberof: '',
    access: ''
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var access_token = this.getCookie('access_token');

    this.interval = setInterval(this.getData, 60000);
    this.getData();

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }

  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getData = () => {
    axios.get(`http://174.32.189.27:8080/api/shippingpriority/inventorynow.php`, {})
    .then(res => {
      const rowData = res.data;
      this.setState({ rowData });
      console.log("Row Data: " + JSON.stringify(rowData));
    });
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Daily Inventory Tracker");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  render() {

     // set background colour on even rows again, this looks bad, should be using CSS classes
     let prevOrder = '99999';
     let counter = 0;
     let style = '';
     let styleColor = '';

     const getRowStyle = params => {
         if(params.data._qty > 0 || params.data._qty == 0){
             counter = 1;
         }
         else{
             counter = 2;
         }
         switch (counter) {
             case 1:
               style = 'green';
               styleColor = 'white';
               prevOrder = params.data.sales_order_number;
               break;
             case 2:
               style = 'red';
               styleColor = 'white';
               prevOrder = params.data.sales_order_number;
               
           }
             return { background: style, color: styleColor };
     };

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Daily Inventory Tracker</title>
            </Helmet>
            {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center'}}>
            <h3>Daily Inventory Tracker</h3>
            </div>
            <div className="ag-theme-alpine" style={{height: '86vh', width: '100%;'}}>
               <AgGridReact defaultColDef={{
                flex: 1,
                minWidth: 100,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true,
                sortable: true,
                filter: true,
                resizable: true,
                cellStyle: {fontWeight: 'bold'}
              }} 
              sideBar={true} 
              rowData={this.state.rowData} 
              rowHeight={50} 
              pagination={true}
              getRowStyle={getRowStyle}
              >
                   <AgGridColumn field="production_id" headerName="Manufacturing Order" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="name" headerName="Operation" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="center" headerName="Work Center" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="_part_name" headerName="Part Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="lot_id" headerName="Lot Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="qty_to_do" headerName="Quantity To Do" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="rem_qty" headerName="Quantity Remaining" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="_qty" headerName="Current Part Quantity" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="create_date" headerName="Created Date" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="date_done" headerName="Finished Date" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="badge_id" headerName="Employee Badge ID" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="_name" headerName="Employee Name" sortable={ true } filter={ true }></AgGridColumn>
               </AgGridReact>
           </div>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default ShipInventoryNow;