import React from 'react';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';
import './login.css';
import axios from 'axios';
import {jsonQuery} from 'json-query';
import { Redirect } from 'react-router';

class Login extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.getCookie = this.getCookie.bind(this);
  this.setCookie = this.setCookie.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: false,
    showLogin:'',
    pin: '',
    rowData: '',
    remember: false,
    scanFail: false,
    showLoginFailed: false,
    isAuth:false,
    redirect: false,
  }
}

componentDidMount() {
  window.addEventListener('load', this.checkLogin);
  //this.startScanner();

    var d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

  

document.addEventListener('keydown', function(e) {
	// add scan property to window if it does not exist
	if(!window.hasOwnProperty('scan')) {
		window.scan = []
	}
	
	// if key stroke appears after 10 ms, empty scan array
	if(window.scan.length > 0 && (e.timeStamp - window.scan.slice(-1)[0].timeStamp) > 10) {
		window.scan = []
	}
	
	// if key store is enter and scan array contains keystrokes
	// dispatch `scanComplete` with keystrokes in detail property
	// empty scan array after dispatching event
	if(e.key === "Enter" && window.scan.length > 0) {
		let scannedString = window.scan.reduce(function(scannedString, entry) {
			return scannedString + entry.key
		}, "")
		window.scan = []
		return document.dispatchEvent(new CustomEvent('scanComplete', {detail: scannedString}))
	}
	
	// do not listen to shift event, since key for next keystroke already contains a capital letter
	// or to be specific the letter that appears when that key is pressed with shift key
	if(e.key !== "Shift") {
		// push `key`, `timeStamp` and calculated `timeStampDiff` to scan array
		let data = JSON.parse(JSON.stringify(e, ['key', 'timeStamp']))
		data.timeStampDiff = window.scan.length > 0 ? data.timeStamp - window.scan.slice(-1)[0].timeStamp : 0;

		window.scan.push(data)
	}
})
// listen to `scanComplete` event on document
document.addEventListener('scanComplete',(e) => { 

  axios.get(`http://174.32.189.27:3000/users/get/` + e.detail, {})
      .then(res => {
        if(res.data[0] && res.data[0]['active'] == "Active"){
          console.log(res.data);
          document.cookie = "username" + "=" + res.data[0]['username'] + ";" + expires + ";path=/";
          document.cookie = "fullname" + "=" + res.data[0]['full_name'] + ";" + expires + ";path=/";
          document.cookie = "loggedin" + "=" + true + ";" + expires + ";path=/";
          document.cookie = "access_token" + "=" + false + ";" + expires + ";path=/";
          document.cookie = "memberof" + "=" + res.data[0]['member_of'] + ";" + expires + ";path=/";
          document.cookie = "remember" + "=" + this.state.remember + ";" + expires + ";path=/";
          this.setState({showLogin: true});
          this.setState({redirect: true});
          //window.location.reload(); 
          var date = new Date();
          var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
          var timeInput = date.toLocaleTimeString();

            var bodyFormData = new FormData();
            bodyFormData.append('username', this.getCookie('username'));
            bodyFormData.append('page', "dashboard");
            bodyFormData.append('action', "login (scan)");
            bodyFormData.append('date', dateInput);
            bodyFormData.append('time', timeInput);

            axios({
              method: "post",
              url: "http://174.32.189.27:8080/api/access/insert.php",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (response) {
                //handle success
                console.log(response);
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });

        }
        else{
          console.log("Error. Operator not found.");
          this.setState({scanFail: true});
        }
      });
})
}

componentWillUnmount() { 
 window.removeEventListener('load', this.checkLogin);
}

setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

checkLogin = () =>{
  if(this.getCookie('username') && this.getCookie('loggedin')){
    this.setState({ login: true }, () => {
      console.log('Already loggedin: ' + this.state.login);
    }); 
    this.setState({redirect: true}, () =>{
      console.log("Redirecting: " + this.state.redirect);
    });
  }
  else{
    console.log('Already loggedout.');
  }
}

handleFormSubmit = e => {
  var data = new FormData();
  
  console.log("CURRENT USERNAME: " + this.state.username);

  data.append('uid', this.state.username);
  data.append('password', this.state.password);

  var date = new Date();
  var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
  var timeInput = date.toLocaleTimeString();

    var bodyFormData = new FormData();
    bodyFormData.append('username', this.state.username);
    bodyFormData.append('page', "dashboard");
    bodyFormData.append('action', "login");
    bodyFormData.append('date', dateInput);
    bodyFormData.append('time', timeInput);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/access/insert.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

  e.preventDefault();
  axios({
    method: 'post',
    url: 'http://174.32.189.27:5000/Login',
    headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' },
    data: data
  })

    .then(result => {
      this.setState({
        data: result.data
      })
      if(result.data[0].login == true){
        console.log("INFO: " + JSON.stringify(result.data));
        console.log("STATE DATA: " + this.state.data);
        console.log("DATA: " + JSON.stringify(result.data[0]));
        this.setCookie('username', result.data[0].username);
        this.setCookie('fullname', result.data[0].displayname);
        this.setCookie('loggedin', result.data[0].session);
        this.setCookie('access_token', result.data[0].access_token);
        this.setCookie('memberof', result.data[0].memberof);
        this.setCookie('remember', this.state.remember);
        this.setState({login: result.data[0].session});
        this.setState({showLogin: true});
        this.setState({redirect: true});
        console.log('SHOW LOGIN: ' + this.state.showLogin); 
        console.log("Access Token Please: " + result.data[0].access_token);
      }
      else{
        this.setState({login: false});
        this.setState({showLogin: false});
        this.setState({showLoginFailed: true});
        console.log('SHOW LOGIN: ' + this.state.showLogin); 
      }
      console.log(result.data[0]);
      localStorage.setItem("Data", JSON.stringify(result.data[0]));
    })
    .catch(error => this.setState({ error: error.message }));
};

  render() {

    return(
      <div className='container animate-bottom'>
        <Navbar></Navbar>
        {this.state.redirect === true &&
              <Redirect to="/dashboard" />
              }
              {this.state.showLogin === false &&
                <div className="alert alert-dismissible alert-danger">
                <strong>Login Failed.</strong> Please try again or contact your network administrator.
              </div>
              }
              {this.state.scanFail === true ? <div className="alert alert-dismissible alert-danger">
                <strong>Scan Failed.</strong> Please try again or contact your network administrator.
              </div> : ''}
        <div className='row'>
          <div className='col-lg-4'></div>
          <div className='col-lg-4'>
          <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" data-bs-toggle="tab" href="#login" aria-selected="true" role="tab">Login</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" data-bs-toggle="tab" href="#operator" aria-selected="false" role="tab" tabIndex={-1}>Operator</a>
            </li>
        </ul>
        <div id="myTabContent" className="tab-content">
          <div className="tab-pane active" id="login" role="tabpanel">
          <form className='cm-login-form'>
            <fieldset>
              <div className="form-group">
                <input type="text" className="form-control" id="username" aria-describedby="usernameHelp" name="username" placeholder="Username" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} required/>
              </div>
              <div className="form-group">
                <input type="password" className="form-control" id="password" name="password" placeholder="Password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} required/>
              </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" defaultValue style={{marginLeft: '-20px'}} onChange={e => this.setState({ remember: e.target.value })}/>
                  <label className="form-check-label">
                    Remember Me
                  </label>
                </div>
              <button type="submit" className="btn btn-primary dash-btn-login" onClick={e => this.handleFormSubmit(e)}>Login</button>
              <div className="form-check forgot-password-link">
                  <label className="form-check-label">
                    Need help logging in? <a style={{color: '#354a5a !important'}} className="accept-terms-link" href="http://54.211.224.243/ITPortal/index.php/help-desk/submit-ticket" target="_blank">Click Here</a>
                  </label>
                </div>
            </fieldset>
          </form>
          </div>
          <div className="tab-pane" id="operator" role="tabpanel">
          <form className='cm-login-form'>
            <fieldset>
                <div className="form-check">
                {/*<div id="reader" width="600px"></div>*/}
                <img className="production-logo" src="./img/id_badge.png" style={{height: '240px', padding: '20px'}} />
                <br></br>
                  <div style={{textAlign: 'center'}}>
                    <p>Press "Enter" and scan ID badge to login.</p>
                  </div>
                </div>
                {/*<div className="form-group">
                <input type="text" className="form-control" id="pin" aria-describedby="pinHelp" name="pin" placeholder="Employee ID Number" value={this.state.pin} onChange={e => this.setState({ pin: e.target.value })} required/>
              </div>
              <button type="submit" className="btn btn-primary dash-btn-login" onClick={e => this.handleFormSubmit(e)}>Login</button>*/}
              <div className="form-check forgot-password-link">
                  <label className="form-check-label">
                    Need help logging in? <a style={{color: '#354a5a !important'}} className="accept-terms-link" href="http://54.211.224.243/ITPortal/index.php/help-desk/submit-ticket" target="_blank">Click Here</a>
                  </label>
          </div>
            </fieldset>
          </form>
          </div>
        </div>
          <div>
</div>
          </div>
          <div className='col-lg-4'></div>
        </div>
        <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
    </div>
    );
  }
}

export default Login;