import { React, useState } from 'react'
import data from "./search.json"

function List(props) {
    //create a new array by filtering the original array
    const filteredData = data.filter((el) => {
        //if no input the return the original
        if (props.input === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.link.toLowerCase().includes(props.input)
        }
    })
    return (
        <ul style={{listStyleType: 'none'}}>
            {filteredData.map((item) => (
                <li>
                    <div className="card" style={{boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important', color: 'white'}}>
                        <div className="card-body">
                        <a style={{color: 'white'}} key={item.name} href={item.name}>{item.link}</a>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default List
