import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import './profile.css';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router';

class Profile extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
  }

  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Profile");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }


  render() {
    return(
      <div className='container animate-bottom'>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - {this.getCookie('username')}</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center'}}>
          <h4>Profile:</h4>
        </div>
        <table className="table" style={{width: '400px', margin: '0 auto', color: 'black'}}>
        <thead>
          <tr>
          </tr>
        </thead>
        <tbody>
        <tr>
            <th scope="row">Full Name:</th>
            <td>{this.getCookie('fullname')}</td>
          </tr>
          <tr>
            <th scope="row">Username:</th>
            <td>CASEMED\{this.state.username}</td>
          </tr>
          <tr>
            <th scope="row">Email Address:</th>
            <td>{this.state.username}@casemed.com</td>
          </tr>
          {/*<tr>
            <th scope="row">Phone Number:</th>
            <td>+1 (201) 313-1999 ext. 201</td>
          </tr>
          <tr>
            <th scope="row">Department:</th>
            <td>Information Technology</td>
          </tr>
          <tr>
            <th scope="row">Occupation</th>
            <td>Senior Systems Analyst</td>
          </tr>*/}
        </tbody>
      </table>
      {/*<hr></hr>
      <div style={{textAlign: 'center'}}>
          <h4>Reset Account Password:</h4>
        </div>
        <form name="passwordChange">
          <table style={{width: '400px', margin: '0 auto'}}>
            <tbody>
              <br></br>
              <tr>
                <th>Username:</th>
                <td><input className="form-control" name="username" type="text" size={20} autoComplete="off" value={this.getCookie('username')} disabled/></td>
              </tr>
              <br></br>
              <tr>
                <th>Old password:</th>
                <td><input className="form-control" name="oldPassword" size={20} type="password" /></td>
              </tr>
              <br></br>
              <tr>
                <th>New password:</th>
                <td><input className="form-control" name="newPassword1" size={20} type="password" /></td>
              </tr>
              <br></br>
              <tr>
                <th>Confirm password:</th>
                <td><input className="form-control" name="newPassword2" size={20} type="password" /></td>
              </tr>
              <br></br>
              <tr>
                <td colSpan={2} style={{textAlign: 'center'}}>
                  <input className="btn btn-secondary" name="submitted" type="submit" value="Change Password" />&nbsp;
                  <button className="btn btn-danger">Cancel</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>*/}
        <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default Profile;