import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import './boards.css';
import axios from 'axios';
import Board from 'react-trello';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router';

class Spotwelding extends React.Component {
  
constructor(props) {
  super(props);

  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    limit: false,
    memberof: '',
    access: '',
    data: {"lanes": [{"id": "Loading...","title": "Loading...","label": "Loading...","cards": []}]},
    nextData: {}
  }

  this.checkLogin = this.checkLogin.bind(this);
  //this.saveBoard = this.saveBoard.bind(this);
  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    this.checkAccess();

    this.interval = setInterval(this.getData, 10000);
    this.getData();

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Production')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getData = () => {
    axios.get(`http://174.32.189.27:3000/spotwelding`)
    .then(res => {
      const data = res.data;
      this.setState({ data });
      //console.log("Row Data: " + JSON.stringify(data));
    });
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  checkAccess = () => {
    if(this.getCookie('memberof').includes("Production")){
      this.setState({limit: true});
      console.log(this.state.limit);
    }
    else{
      this.setState({limit: false});
      console.log(this.state.limit);
    }
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Spot Welding Board");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  shouldReceiveNewData = (nextData) => {
    console.log('Data Changed');
    //console.log(JSON.stringify(nextData));

    let data = nextData;
    this.setState({ nextData: data });
    console.log(JSON.stringify(this.state.nextData));
    
  }  

  saveBoard = () =>{

    console.log("HELLO PERSON");

    var config = {
      method: 'post',
      url: 'http://174.32.189.27:3000/updatespotwelding',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : JSON.stringify(this.state.nextData)
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    return(
      <div className='container animate-bottom' style={{maxWidth: '100%'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Spot Welding Board</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
        <div>
        {this.state.limit == false ?<div>
          <button onClick={this.saveBoard} className='sc-jdAMXn tGzvb'>Save</button>
          <Board style={{backgroundColor: 'transparent', overflow: 'inherit'}} data={this.state.data} onDataChange={this.shouldReceiveNewData} editable draggable canAddLanes editLaneTitle addLaneTitle="Add Column" addCardTitle="Add Item"/>
           </div>: 
           <Board style={{backgroundColor: 'transparent', overflow: 'inherit'}} data={this.state.data} />}
           </div>
        <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default Spotwelding;