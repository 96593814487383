import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import './exports.css';
import { Bars } from  'react-loader-spinner';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class PurchaseProd extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: '',
    fromDate: '',
    toDate: '',
    memberof: '',
    loading: false,
    access: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('Accounting') || memberof.includes('Administrator')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChangeFrom = (event) => {
    this.setState({fromDate: event.target.value});
    console.log("FROM DATE: " + this.state.fromDate);
  }

  handleChangeTo = (event) => {
    this.setState({toDate: event.target.value});
    console.log("TO DATE: " + this.state.toDate);
  }

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getInfo = (e) => {
    e.preventDefault();

    var access_token = this.getCookie('access_token');

    this.setState({loading: true});

    axios.get(`http://174.32.189.27:8080/api/exports/purchase.php?fromDate=` + this.state.fromDate, {})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        console.log("Row Data: " + JSON.stringify(rowData));
        this.setState({loading: false});
      });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onBtnExport = () => {
    this.gridApi.exportDataAsCsv(getParams());
  };

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Purchased Products");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  render() {

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Purchased Products</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center'}}>
            <h3>Purchased Products</h3>
            <p>(Please select "From" date to view report.)</p>
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary filter-nav">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse" id="navbarColor01">
                        <form className="d-flex" onSubmit={this.getInfo}>
                            <h4>From:</h4>&nbsp;
                            <input className="form-control me-sm-2 round-date" type="date" id="fromDate" onChange={this.handleChangeFrom}/>&nbsp;
                            <button className="btn btn-secondary my-2 my-sm-0" type="submit"><i className="fa fa-search" aria-hidden="true"/>&nbsp;Search</button>
                        </form>
                        <button className="btn btn-secondary my-2 my-sm-0" onClick={() => this.onBtnExport()}><i className="fa fa-file-excel-o" aria-hidden="true" />&nbsp;Export to CSV</button>
                    </div>
                </div>
            </nav>
            {this.state.loading == true ?
            <div style={{justifyContent: 'center'}}>
             <Bars
             height="100"
             width="100"
             color='grey'
             ariaLabel='loading'
             style={{justifyContent: 'center'}}
           />
           </div>
             : ''}
            {this.state.rowData == '' ?
            '': <div className="ag-theme-alpine" style={{height: '86vh', width: '100%;'}}>
            <AgGridReact defaultColDef={{
             flex: 1,
             minWidth: 100,
             enableValue: true,
             enableRowGroup: true,
             enablePivot: true,
             sortable: true,
             filter: true,
             resizable: true,
             cellStyle: {fontWeight: 'bold'}
           }} 
           sideBar={true} 
           rowData={this.state.rowData} 
           rowHeight={50} 
           pagination={false}
           onGridReady={this.onGridReady}
           >
                <AgGridColumn field="part_number" headerName="Part Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="description" headerName="Description" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="assigned_user_id" headerName="Assigned User Id" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="date_created" headerName="Date Created" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="date_modified" headerName="Date Modified" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="created_by" headerName="Created By" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="modified_by" headerName="Modified By" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="deleted" headerName="Deleted" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="customer_po_number" headerName="Customer PO Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="price" headerName="Price" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="quantity" headerName="Quantity" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="facility_city" headerName="Facility City" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="facility_state" headerName="Facility State" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="product_category" headerName="Product Category" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="territory_zip" headerName="Territory Zip" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="sordr_sale8694_module_ida" headerName="sordr_sale8694_module_ida" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="accounts_prods_purchased_products_new_1accounts_ida" headerName="accounts_prods_purchased_products_new_1accounts_ida" sortable={ true } filter={ true }></AgGridColumn>
            </AgGridReact>
        </div>}
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

function getParams() {
    return {
      fileName: "purchased_products.csv",
    };
  }

export default PurchaseProd;