import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import './exports.css';
import { Bars } from  'react-loader-spinner';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class QuickBooks extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: '',
    fromDate: '',
    toDate: '',
    memberof: '',
    loading: false,
    access: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('Accounting') || memberof.includes('Administrator')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChangeFrom = (event) => {
    this.setState({fromDate: event.target.value});
    console.log("FROM DATE: " + this.state.fromDate);
  }

  handleChangeTo = (event) => {
    this.setState({toDate: event.target.value});
    console.log("TO DATE: " + this.state.toDate);
  }

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getInfo = (e) => {
    e.preventDefault();

    var access_token = this.getCookie('access_token');

    this.setState({loading: true});

    axios.get(`http://174.32.189.27:8080/api/exports/quickbooks.php?fromDate=` + this.state.fromDate, {})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        console.log("Row Data: " + JSON.stringify(rowData));
        this.setState({loading: false});
      });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onBtnExport = () => {
    this.gridApi.exportDataAsCsv(getParams());
  };

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Quickbooks export");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  render() {

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - QuickBooks</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center'}}>
            <h3>QuickBooks</h3>
            <p>(Please select "From" date to view report.)</p>
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary filter-nav">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse" id="navbarColor01">
                        <form className="d-flex" onSubmit={this.getInfo}>
                            <h4>From:</h4>&nbsp;
                            <input className="form-control me-sm-2 round-date" type="date" id="fromDate" onChange={this.handleChangeFrom}/>&nbsp;
                            <button className="btn btn-secondary my-2 my-sm-0" type="submit"><i className="fa fa-search" aria-hidden="true"/>&nbsp;Search</button>
                        </form>
                        <button className="btn btn-secondary my-2 my-sm-0" onClick={() => this.onBtnExport()}><i className="fa fa-file-excel-o" aria-hidden="true" />&nbsp;Export to CSV</button>
                    </div>
                </div>
            </nav>
            {this.state.loading == true ?
            <div style={{justifyContent: 'center'}}>
             <Bars
             height="100"
             width="100"
             color='grey'
             ariaLabel='loading'
             style={{justifyContent: 'center'}}
           />
           </div>
             : ''}
            {this.state.rowData == '' ?
            '': <div className="ag-theme-alpine" style={{height: '86vh', width: '100%;'}}>
            <AgGridReact defaultColDef={{
             flex: 1,
             minWidth: 100,
             enableValue: true,
             enableRowGroup: true,
             enablePivot: true,
             sortable: true,
             filter: true,
             resizable: true,
             cellStyle: {fontWeight: 'bold'}
           }} 
           sideBar={true} 
           rowData={this.state.rowData} 
           rowHeight={50} 
           pagination={false}
           onGridReady={this.onGridReady}
           >
                <AgGridColumn field="invoice_description" headerName="Invoice Description" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="case_medical_id" headerName="Case Medical ID" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="ship_via" headerName="Ship Via" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="product_id" headerName="Product ID" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="product_name" headerName="Product Name" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="product_description" headerName="Product Description" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="quantity" headerName="Quantity" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="price" headerName="Price" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="total" headerName="Total" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="invoice_number" headerName="Invoice Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="date" headerName="Date" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_name" headerName="Partner Name" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_street" headerName="Partner Street" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_street_two" headerName="Partner Street Two" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_city" headerName="Partner City" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_state" headerName="Partner State" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_zip_code" headerName="Partner Zip Code" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="partner_country" headerName="Partner Country" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="customer_name" headerName="Customer Name" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="delivery_street" headerName="Delivery Street" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="delivery_street_two" headerName="Delivery Street Two" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="delivery_city" headerName="Delivery City" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="delivery_state" headerName="Delivery State" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="delivery_zip_code" headerName="Delivery Zip Code" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="Delivery_country" headerName="Delivery Country" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="customer_po_number" headerName="Customer PO Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="sales_order_number" headerName="Sales Order Number" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="tax_code" headerName="Tax Code" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="taxes" headerName="Taxes" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="taxable" headerName="Taxable" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="sales_rep_id" headerName="Sales Rep ID" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="gpo_info" headerName="GPO Info" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="product_category" headerName="Product Category" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="payment_terms" headerName="Payment Terms" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="pricelist_id" headerName="Pricelist ID" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="pricelist_name" headerName="Pricelist Name" sortable={ true } filter={ true }></AgGridColumn>
                <AgGridColumn field="customer_case_medical_id" headerName="Customer Case Medical ID" sortable={ true } filter={ true }></AgGridColumn>
            </AgGridReact>
        </div>}
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

function getParams() {
    return {
      fileName: "quickbooks.csv",
    };
  }

export default QuickBooks;