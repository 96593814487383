import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class Modify extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: [],
    partData: [],
    values: [],
    modalIsOpen: false,
    setIsOpen: false,
    operator: '',
    part_num: '',
    part_num_mod: '',
    part_qty: '',
    part_qty: '',
    lot_num: '',
    shop_order_num: '',
    anodize_thickness: '',
    line_num: '',
    line_num_mod: '',
    tank_num: '',
    time_in: '',
    rack_qty: '',
    rack_qty: '',
    rack_type: '',
    rack_type_mod: '',
    temp: '',
    amps: '',
    ampsData: '',
    thick: '',
    extra: '',
    fin: '',
    time_out: '',
    note: '',
    thickness: '',
    final_thickness: '',
    extra_time: '',
    qc_sign: '',
    qc_pass: '',
    qc_rework: '',
    qc_scrap: '',
    comment: '',
    split_order: '',
    log_ID: '',
    phase: '',
    timestamp: '',
    access: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }

    var access_token = this.getCookie('access_token');


    axios.get(`http://174.32.189.27:8080/api/anodize/getparts.php`, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const partData = res.data;
        this.setState({ partData });
        console.log("Part Data: " + JSON.stringify(partData));
      });

      axios.get(`http://174.32.189.27:8080/api/anodize/anodize_entry.php?id=` + this.props.location.state.id, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        this.setState({ operator: res.data[0].operator });
        this.setState({ part_num: res.data[0].part_num });
        this.setState({ part_qty: res.data[0].part_quantity });
        this.setState({ rack_qty: res.data[0].rack_quantity });
        this.setState({ rack_type: res.data[0].rack_type });
        this.setState({ lot_num: res.data[0].lot_num });
        this.setState({ shop_order_num: res.data[0].shop_order_num });
        this.setState({ time_in: res.data[0].time_in_anodize });
        this.setState({ temp: res.data[0].anodize_temp });
        this.setState({ amps: res.data[0].amps });
        this.setState({ tank_num: res.data[0].tank_num });
        this.setState({ line_num: res.data[0].line_num });
        this.setState({ anodize_thickness: res.data[0].anodize_thickness });
        this.setState({ time_out: res.data[0].time_out });
        this.setState({ note: res.data[0].note });
        this.setState({ log_ID: res.data[0].log_ID });
        this.setState({ thickness: res.data[0].thickness });
        this.setState({ final_thickness: res.data[0].final_thickness });
        this.setState({ extra_time: res.data[0].extra_time });
        this.setState({ qc_sign: res.data[0].qc_sign });
        this.setState({ qc_pass: res.data[0].qc_pass });
        this.setState({ qc_rework: res.data[0].qc_rework });
        this.setState({ qc_scrap: res.data[0].qc_scrap });
        this.setState({ split_order: res.data[0].split_order });
        this.setState({ comment: res.data[0].comment });
        this.setState({ phase: res.data[0].phase });
        this.setState({ timestamp: res.data[0].timestamp });
        console.log("Row Data: " + JSON.stringify(rowData));
      });

  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');

        var date = new Date();
  var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
  var timeInput = date.toLocaleTimeString();

    var bodyFormData = new FormData();
    bodyFormData.append('username', this.getCookie('username'));
    bodyFormData.append('page', "Anodize Modify Log");
    bodyFormData.append('action', "access");
    bodyFormData.append('date', dateInput);
    bodyFormData.append('time', timeInput);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/access/insert.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  calculateAmps = () => {

    var d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

   axios.get(`http://174.32.189.27:8080/api/anodize/calculate_amps.php?pn=` + localStorage.getItem("part_num_mod") + `&pq=` + localStorage.getItem("part_qty_mod") + `&rq=` + localStorage.getItem("rack_qty_mod") + `&line=` + localStorage.getItem("line_num_mod"))
      .then(res => {
        const ampsData = res.data;
        this.setState({ ampsData });
        console.log("Amp Data: " + JSON.stringify(ampsData));
        localStorage.setItem("amps", ampsData);
      });
  }

  handleChangePart = (event) => {
    this.setState({part_num_mod: event.target.value});
    console.log("PART NUMBER: " + this.state.part_num);
    localStorage.setItem("part_num_mod", event.target.value);
    this.calculateAmps();
  }

  handleChangePartQty = (event) => {
    this.setState({part_qty_mod: event.target.value});
    console.log("PART QTY: " + this.state.part_qty);
    localStorage.setItem("part_qty_mod", event.target.value);
    this.calculateAmps();
  }

  handleChangeRackQty = (event) => {
    this.setState({rack_qty_mod: event.target.value});
    console.log("RACK QTY: " + this.state.rack_qty);
    localStorage.setItem("rack_qty_mod", event.target.value);
    this.calculateAmps();
  }

  handleChangeRackType = (event) => {
    this.setState({rack_type_mod: event.target.value});
    console.log("RACK TYPE: " + this.state.rack_type);
    localStorage.setItem("rack_type_mod", event.target.value);
    this.calculateAmps();
  }

  handleChangeLine = (event) => {
    this.setState({line_num_mod: event.target.value});
    console.log("LINE #: " + this.state.line_num);
    localStorage.setItem("line_num_mod", event.target.value);
    this.calculateAmps();
  }

  addMod = (event) => {
    event.preventDefault();
    //console.log("INFO TO POST: " + this.state.currentPart + "," + this.state.currentPartArea + "," + this.state.currentRackArea + "," + this.state.currentAlloyType);

    var bodyFormData = new FormData();

    //Original Data
    bodyFormData.append('operator', event.target.operator.value);
    bodyFormData.append('part_num', event.target.part_num.value);
    bodyFormData.append('part_quantity', event.target.part_quantity.value);
    bodyFormData.append('rack_quantity', event.target.rack_quantity.value);
    bodyFormData.append('amps', event.target.amps.value);
    bodyFormData.append('rack_type', event.target.rack_type.value);
    bodyFormData.append('tank_num', event.target.tank_num.value);
    bodyFormData.append('line_num', event.target.line_num.value);
    bodyFormData.append('time_in_anodize', event.target.time_in_anodize.value);
    bodyFormData.append('lot_num', event.target.lot_num.value);
    bodyFormData.append('shop_order_num', event.target.shop_order_num.value);
    bodyFormData.append('anodize_temp', event.target.anodize_temp.value);
    bodyFormData.append('anodize_thickness', event.target.anodize_thickness.value);
    bodyFormData.append('extra_time', event.target.extra_time.value);
    bodyFormData.append('anodize_final_thickness', event.target.anodize_final_thickness.value);
    bodyFormData.append('note', event.target.note.value);
    bodyFormData.append('time_out', event.target.time_out.value);
    bodyFormData.append('log_id', event.target.log_id.value);
    bodyFormData.append('original_date', event.target.original_date.value);
    bodyFormData.append('phase', event.target.phase.value);
    bodyFormData.append('qc_sign', event.target.qc_sign.value);
    bodyFormData.append('qc_pass', event.target.qc_pass.value);
    bodyFormData.append('qc_rework', event.target.qc_rework.value);
    bodyFormData.append('qc_scrap', event.target.qc_scrap.value);
    bodyFormData.append('split_order', event.target.split_order.value);
    bodyFormData.append('comment', event.target.comment.value);

    //Modified Data
    bodyFormData.append('operator_mod', event.target.operator_mod.value);
    bodyFormData.append('part_num_mod', event.target.part_num_mod.value);
    bodyFormData.append('part_quantity_mod', event.target.part_quantity_mod.value);
    bodyFormData.append('rack_quantity_mod', event.target.rack_quantity_mod.value);
    bodyFormData.append('amps_mod', event.target.amps_mod.value);
    bodyFormData.append('rack_type_mod', event.target.rack_type_mod.value);
    bodyFormData.append('tank_num_mod', event.target.tank_num_mod.value);
    bodyFormData.append('line_num_mod', event.target.line_num_mod.value);
    bodyFormData.append('time_in_anodize_mod', event.target.time_in_anodize_mod.value);
    bodyFormData.append('lot_num_mod', event.target.lot_num_mod.value);
    bodyFormData.append('shop_order_num_mod', event.target.shop_order_num_mod.value);
    bodyFormData.append('anodize_temp_mod', event.target.anodize_temp_mod.value);
    bodyFormData.append('anodize_thickness_mod', event.target.anodize_thickness_mod.value);
    bodyFormData.append('extra_time_mod', event.target.extra_time_mod.value);
    bodyFormData.append('anodize_final_thickness_mod', event.target.anodize_final_thickness_mod.value);
    bodyFormData.append('note_mod', event.target.note_mod.value);
    bodyFormData.append('time_out_mod', event.target.time_out_mod.value);
    bodyFormData.append('qc_sign_mod', event.target.qc_sign_mod.value);
    bodyFormData.append('qc_pass_mod', event.target.qc_pass_mod.value);
    bodyFormData.append('qc_rework_mod', event.target.qc_rework_mod.value);
    bodyFormData.append('qc_scrap_mod', event.target.qc_scrap_mod.value);
    bodyFormData.append('split_order_mod', event.target.split_order_mod.value);
    bodyFormData.append('comment_mod', event.target.comment_mod.value);
    bodyFormData.append('modified_date', event.target.modified_date.value);
    bodyFormData.append('modified_time', event.target.modified_time.value);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/anodize/submit_mod.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        window.location.replace(window.location.origin + '/anodizelog');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.reload(false);
      });
  }

  render() {

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
              <form className="form-main" onSubmit={this.addMod}>
        <div className="row mt-3">
          <div className="col">
            <div className="px-5" style={{backgroundColor: 'white !important'}}>
              <div className="card-body" style={{color: 'black', border: '1px solid'}}>
                <h2 className="card-title my-3">Original Record</h2>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="modified_date">Record Date</label>
                      <input type="text" name="original_date" defaultValue={this.state.timestamp} className="form-control" readOnly />
                      <input type="text" name="log_id" defaultValue={this.state.log_ID} readOnly style={{display: 'none'}} />
                      <input type="text" name="phase" defaultValue={this.state.phase} readOnly style={{display: 'none'}} />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Operator</label>
                      <input type="text" required className="form-control" name="operator" id="operator" readOnly defaultValue={this.state.operator} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Part #</label>
                      <input type="text" required className="form-control" name="part_num" autoComplete="off" defaultValue={this.state.part_num} readOnly />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Parts Quantity</label>
                      <input type="number" min={1} required name="part_quantity" className="form-control" autoComplete="off" defaultValue={this.state.part_qty} readOnly />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Lot #</label>
                      <input type="text" required className="form-control" name="lot_num" autoComplete="off" defaultValue={this.state.lot_num} readOnly />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Shop Order #</label>
                      <input type="text" required className="form-control" name="shop_order_num" autoComplete="off" defaultValue={this.state.shop_order_num} readOnly />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Line #</label>
                      <input type="text" required className="form-control" name="line_num" autoComplete="off" defaultValue={this.state.line_num} readOnly />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Tank #</label>
                      <input type="number" required className="form-control" name="tank_num" autoComplete="off" defaultValue={this.state.tank_num} readOnly />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Time In</label>
                      <input type="time" required name="time_in_anodize" className="form-control" autoComplete="off" defaultValue={this.state.time_in} readOnly />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rack Quantity</label>
                      <input type="number" min={1} required name="rack_quantity" className="form-control" autoComplete="off" defaultValue={this.state.rack_qty} readOnly />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rack Type</label>
                      <input type="text" defaultValue={this.state.rack_type} name="rack_type" className="form-control" required readOnly />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Temperature (C)</label>
                      <input type="text" className="form-control" name="anodize_temp" required readOnly autoComplete="off" defaultValue={this.state.temp} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Amps</label>
                      <input type="text" className="form-control" name="amps" required readOnly autoComplete="off" defaultValue={this.state.amps} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Thickness</label>
                      <input type="text" required className="form-control" name="anodize_thickness" autoComplete="off" defaultValue={this.state.thickness} readOnly />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Extra Time</label>
                      <input type="text" required name="extra_time" className="form-control" autoComplete="off" defaultValue={this.state.extra_time} readOnly />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Final Thickness</label>
                      <input type="text" required className="form-control" name="anodize_final_thickness" autoComplete="off" defaultValue={this.state.final_thickness} readOnly />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Time Out</label>
                      <input type="time" required className="form-control" name="time_out" autoComplete="off" defaultValue={this.state.time_out} readOnly />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Notes</label>
                      <textarea className="form-control" name="note" autoComplete="off" readOnly defaultValue={this.state.note} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">QC Sign</label>
                      <input type="text" required className="form-control" name="qc_sign" id="operator" readOnly defaultValue={this.state.qc_sign} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Pass</label>
                      <input type="text" required className="form-control" name="qc_pass" autoComplete="off" readOnly defaultValue={this.state.qc_pass} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rework</label>
                      <input type="text" required name="qc_rework" className="form-control" autoComplete="off" readOnly defaultValue={this.state.qc_rework}/>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Scrap</label>
                      <input type="text" required className="form-control" name="qc_scrap" autoComplete="off" readOnly defaultValue={this.state.qc_scrap} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Split Order</label>
                      <input type="text" required className="form-control" name="split_order" autoComplete="off" readOnly defaultValue={this.state.split_order} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Comments</label>
                      <input type="text" required className="form-control" name="comment" autoComplete="off" readOnly defaultValue={this.state.comment} />
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col text-center">
                    <button type="submit" disabled style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info p-3 px-5">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="px-5" style={{backgroundColor: 'white !important'}}>
              <div className="card-body" style={{color: 'black', border: '1px solid'}}>
                <div className="row">
                  <div className="col">
                    <h2 className="card-title my-3">Modified Record</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="modified_date">Record Date</label>
                      <input type="date" name="modified_date" defaultValue className="form-control" required />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="modified_date">Record Time</label>
                      <input type="time" name="modified_time" defaultValue className="form-control" required />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Operator</label>
                      <input type="text" required className="form-control" name="operator_mod" id="operator" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Part #</label>
                      <select required className="form-control" name="part_num_mod" onChange={this.handleChangePart} autoComplete="off" defaultValue>
                      <option></option>
                                {this.state.partData.map((info) => {
                                    return <option value={info.part}>{info.part}</option>
                                })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Parts Quantity</label>
                      <input type="number" min={1} required name="part_quantity_mod" className="form-control" onChange={this.handleChangePartQty} autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Lot #</label>
                      <input type="text" required className="form-control" name="lot_num_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Shop Order #</label>
                      <input type="text" required className="form-control" name="shop_order_num_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Line #</label>
                            <select required className="form-control check" id="line_num_mod" name="line_num_mod" onChange={this.handleChangeLine} autoComplete="off">
                              <option></option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                            </div>
                        </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Tank #</label>
                      <input type="number" required className="form-control" name="tank_num_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Time In</label>
                      <input type="time" required name="time_in_anodize_mod" className="form-control" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rack Quantity</label>
                      <input type="number" min={1} required name="rack_quantity_mod" className="form-control" onChange={this.handleChangeRackQty} autoComplete="off" defaultValue />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rack Type</label>
                      <select name="rack_type_mod" className="form-control" onChange={this.handleChangeRackType} required>
                        <option value />
                        <option value="T">T</option>
                        <option value="A">A</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Temperature (C)</label>
                      <input type="text" className="form-control" name="anodize_temp_mod" required autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Amps</label>
                      <input type="text" className="form-control" name="amps_mod" required autoComplete="off" value={this.state.ampsData} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Thickness</label>
                      <input type="text" required className="form-control" name="anodize_thickness_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Extra Time</label>
                      <input type="text" required name="extra_time_mod" className="form-control" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Final Thickness</label>
                      <input type="text" required className="form-control" name="anodize_final_thickness_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Time Out</label>
                      <input type="time" required className="form-control" name="time_out_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Notes</label>
                      <textarea className="form-control" name="note_mod" autoComplete="off" defaultValue={""} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">QC Sign</label>
                      <input type="text" required className="form-control" name="qc_sign_mod" id="operator" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Pass</label>
                      <input type="text" required className="form-control" name="qc_pass_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Rework</label>
                      <input type="text" required name="qc_rework_mod" className="form-control" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Scrap</label>
                      <input type="text" required className="form-control" name="qc_scrap_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Split Order</label>
                      <input type="text" required className="form-control" name="split_order_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Comments</label>
                      <input type="text" required className="form-control" name="comment_mod" autoComplete="off" defaultValue />
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col text-center">
                    <button type="submit" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info p-3 px-5">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default Modify;