import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import './prod.css';
import 'react-loading-skeleton/dist/skeleton.css';

class SetupSheets extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    part: '',
    fileURL: '',
    random: 0,
    username: '',
    password: '',
    fullname: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    numPages: null,
    setNumPages: null,
    pageNumber: 1,
    setPageNumber: 1,
    memberof: '',
    access: '',
  }

  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);


    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Production')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChange = (event) => {
    this.setState({part: event.target.value});
    console.log("ORDER NUMBER: " + this.state.part);
  }

  getDrawing = () => {
    fetch('http://174.32.189.27:3000/api/getsheet/' + this.state.part)
    .then((response) => {
      response.blob()
      .then(blob => {
        const fileUrl = URL.createObjectURL(blob);
        this.setState({fileURL: fileUrl});
        //window.open(fileUrl);
      })
  })
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetch('http://174.32.189.27:3000/api/getsheet/' + this.state.part)
      .then((response) => {
        response.blob()
        .then(blob => {
          const fileUrl = URL.createObjectURL(blob);
          this.setState({fileURL: fileUrl});
          //window.open(fileUrl);
        })
    })
    }
  }

  resetIframe = () => {
    this.setState({random: this.state.random + 1});
}

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Setup Sheets");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }


  render() {

    return(
      <div className='row animate-bottom' style={{width: '100%', padding: '10px'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Setup Sheets</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
            <div className="col-2" style={{borderTopRightRadius: '25px', borderBottomRightRadius: '25px', backgroundColor: '#354a5a', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important'}}>
                <label style={{marginTop: '20px', color: 'white'}}>Enter Shop Order number:</label>
                <input type="text" required name="part_num" id="part_num" className="form-control check" style={{borderRadius: '5px'}} onKeyDown={this._handleKeyDown} onChange={this.handleChange}/>
                <br></br>
                <button className="btn btn-secondary side-btn" onClick={this.getDrawing}><i className="fa fa-search" aria-hidden="true" />&nbsp;Search</button>
            </div>
            <div className="col-10" style={{height: '100vh'}}>
            {this.state.fileURL == '' ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20%'}}><h3>Enter shop order number in sidebar search box to view setup sheet.</h3></div> : <iframe key={this.state.random} src={this.state.fileURL} title="description" style={{width: '100%', height: '100%'}}></iframe> }
            </div>
            <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
    </div>
    );
  }
}

export default SetupSheets;