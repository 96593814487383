import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from 'react-modal';
import Whiteboard from '../whiteboard/whiteboard';

class StageTwo extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: [],
    values: [],
    modalIsOpen: false,
    setIsOpen: false,
    operator: '',
    part_num: '',
    part_qty: '',
    lot_num: '',
    shop_order_num: '',
    anodize_thickness: '',
    line_num: '',
    tank_num: '',
    time_in: '',
    rack_qty: '',
    rack_type: '',
    temp: '',
    amps: '',
    thick: '',
    extra: '',
    fin: '',
    time_out: '',
    note: '',
    log_ID: '',
    access:'',
    extra_time:'',
    final_thickness: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Anodizing')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }

    var access_token = this.getCookie('access_token');

      axios.get(`http://174.32.189.27:8080/api/anodize/anodize_entry.php?id=` + this.props.location.state.id, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        this.setState({ operator: res.data[0].operator });
        this.setState({ part_num: res.data[0].part_num });
        this.setState({ part_qty: res.data[0].part_quantity });
        this.setState({ rack_qty: res.data[0].rack_quantity });
        this.setState({ rack_type: res.data[0].rack_type });
        this.setState({ lot_num: res.data[0].lot_num });
        this.setState({ shop_order_num: res.data[0].shop_order_num });
        this.setState({ time_in: res.data[0].time_in_anodize });
        this.setState({ temp: res.data[0].anodize_temp });
        this.setState({ amps: res.data[0].amps });
        this.setState({ tank_num: res.data[0].tank_num });
        this.setState({ line_num: res.data[0].line_num });
        this.setState({ anodize_thickness: res.data[0].anodize_thickness });
        this.setState({ time_out: res.data[0].time_out });
        this.setState({ note: res.data[0].note });
        this.setState({ log_ID: res.data[0].log_ID });
        this.setState({ extra_time: res.data[0].extra_time });
        this.setState({ final_thickness: res.data[0].final_thickness });
        console.log("Row Data: " + JSON.stringify(rowData));
      });

  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');

        var date = new Date();
        var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
        var timeInput = date.toLocaleTimeString();
      
          var bodyFormData = new FormData();
          bodyFormData.append('username', this.getCookie('username'));
          bodyFormData.append('page', "Anodize Stage Two");
          bodyFormData.append('action', "access");
          bodyFormData.append('date', dateInput);
          bodyFormData.append('time', timeInput);
      
          axios({
            method: "post",
            url: "http://174.32.189.27:8080/api/access/insert.php",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              //handle success
              console.log(response);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
            
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  addStageTwo = (event) => {
    event.preventDefault();
    //console.log("INFO TO POST: " + this.state.currentPart + "," + this.state.currentPartArea + "," + this.state.currentRackArea + "," + this.state.currentAlloyType);

    var bodyFormData = new FormData();
    bodyFormData.append('operator', event.target.operator.value);
    bodyFormData.append('part_num', event.target.part_num.value);
    bodyFormData.append('part_quantity', event.target.part_quantity.value);
    bodyFormData.append('rack_quantity', event.target.rack_quantity.value);
    bodyFormData.append('amps', event.target.amps.value);
    bodyFormData.append('rack_type', event.target.rack_type.value);
    bodyFormData.append('tank_num', event.target.tank_num.value);
    bodyFormData.append('line_num', event.target.line_num.value);
    bodyFormData.append('time_in_anodize', event.target.time_in_anodize.value);
    bodyFormData.append('lot_num', event.target.lot_num.value);
    bodyFormData.append('shop_order_num', event.target.shop_order_num.value);
    bodyFormData.append('anodize_temp', event.target.anodize_temp.value);
    bodyFormData.append('anodize_thickness', event.target.anodize_thickness.value);
    bodyFormData.append('extra_time', event.target.extra_time.value);
    bodyFormData.append('anodize_final_thickness', event.target.anodize_final_thickness.value);
    bodyFormData.append('note', event.target.note.value);
    bodyFormData.append('time_out', event.target.time_out.value);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/anodize/submit_stagetwo.php?log_id=" + this.state.log_ID,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        window.location.replace(window.location.origin + '/anodizelog');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.reload(false);
      });
  }

  render() {

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
              <div className="container">
                <h3>Anodizing Stage One</h3>
                <h4>Please review and complete this record.</h4>
                <p>If you see any errors, please contact an administrator (ext. 211, ext. 214)</p>
                <form className="form-main" method="post" onSubmit={this.addStageTwo}>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Operator</label>
                        <input type="text" required className="form-control" name="operator" id="operator" value={this.state.operator} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Part #</label>
                        <input type="text" required className="form-control" name="part_num" autoComplete="off" value={this.state.part_num} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Parts Quantity</label>
                        <input type="number" min="1" required name="part_quantity" className="form-control" autoComplete="off" value={this.state.part_qty} readOnly />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Lot #</label>
                        <input type="text" required className="form-control" name="lot_num" autoComplete="off" value={this.state.lot_num} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Shop Order #</label>
                        <input type="text" required className="form-control" name="shop_order_num" autoComplete="off" value={this.state.shop_order_num} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Line #</label>
                        <input type="text" required className="form-control" name="line_num" autoComplete="off" value={this.state.line_num} readOnly/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Tank #</label>
                        <input type="text" required className="form-control" name="tank_num" autoComplete="off" value={this.state.tank_num} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Time In</label>
                        <input type="time" required name="time_in_anodize" className="form-control" autoComplete="off" value={this.state.time_in} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Rack Quantity</label>
                        <input type="number" min="1" required name="rack_quantity" className="form-control" autoComplete="off" value={this.state.rack_qty} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Rack Type</label>
                        <input type="text" name="rack_type" className="form-control" value={this.state.rack_type} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Temperature (C)</label>
                        <input type="text" className="form-control" name="anodize_temp" value={this.state.temp} readOnly/>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Amps</label>
                        <input type="text" className="form-control" name="amps" required value={this.state.amps} readOnly/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Thickness</label>
                        <input type="text" required className="form-control" name="anodize_thickness" defaultValue={this.state.anodize_thickness} autoComplete="off" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Extra Time</label>
                        <input type="text" required name="extra_time" className="form-control pending" autoComplete="off" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Final Thickness</label>
                        <input type="text" required className="form-control pending" name="anodize_final_thickness" autoComplete="off" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Time Out</label>
                        <input type="time" required className="form-control" name="time_out" defaultValue={this.state.time_out} autoComplete="off" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Notes</label>
                                <textarea name="note" className="form-control" defaultValue={this.state.note}></textarea>
                              </div>
                          </div>
                        </div>
                  <button type="submit" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info mx-3 py-2 px-4 switch">Submit</button>
                  <Link to={{pathname: '/anodizelog'}}><button style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-danger mx-3 py-2 px-4">Cancel</button></Link>
                </form>
                </div>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default StageTwo;