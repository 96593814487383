import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import './prod.css';
import 'react-loading-skeleton/dist/skeleton.css';

class Anodizing extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    part: '',
    fileURL: '',
    random: 0,
    username: '',
    password: '',
    fullname: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    name: '',
    productId: '',
    productQty: '',
    lotno: '',
    partno: '',
    qty: '',
    status: '',
    rowData: '',
    numPages: null,
    setNumPages: null,
    pageNumber: 1,
    setPageNumber: 1,
    memberof: '',
    access: '',
  }

  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Anodizing')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChange = (event) => {
    this.setState({part: event.target.value});
    console.log("PART NUMBER: " + this.state.part);
  }

  getPartLot = (e) => {
    e.preventDefault();

    axios.get(`http://174.32.189.27:8080/api/partlots/partlots.php?partNum=` + this.state.part, {})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        console.log("Row Data: " + JSON.stringify(rowData));
        this.setState({loading: false});
      });
  }

  resetIframe = () => {
    this.setState({random: this.state.random + 1});
}

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Anodize Sheet");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }


  render() {

    return(
      <div className='row animate-bottom' style={{width: '100%', padding: '10px'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Anodizing Sheet</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center', width: '100%', marginBottom: '25px'}}>
            <h3>Anodizing Sheet</h3>
            </div>
        <iframe src="https://docs.google.com/spreadsheets/d/1_ZV2uySgdeQZyH5xzNwW_jaD-kfN0pjyM3wbMx9byl0/edit?usp=sharing?&amp;rm=minimal&amp;single=true&amp;" style={{width: '100%', height: '100vh'}}></iframe>
            <div style={{textAlign: 'center', width: '100%'}}>
        <Footer></Footer>
        </div>
    </div>
    );
  }
}

export default Anodizing;