import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import './dashboard.css';
import axios from 'axios';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import Clock from 'react-live-clock';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'react-loading-skeleton/dist/skeleton.css';
import List from "./list.js";

class Dashboard extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    fullname: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    results: [],
    query: '',
    inputText: '',
    setInputText: '',
    showList: '',
  }

  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        console.log('Already loggedin');
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  handleChange = (event) => {
    this.setState({query: event.target.value});
    console.log("SEARCH QUERY: " + this.state.query);
  }

  inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    this.setState({inputText: lowerCase});
    if(lowerCase == ""){
      this.setState({showList: false});
    }
    else{
      this.setState({showList: true});
    }
  };

  searchHandler = () => {
    this.setState({showList: true});
    if(this.state.inputText == ""){
      this.setState({showList: false});
    }
  };

  handleSearch = () => {
    fetch("https://www.googleapis.com/customsearch/v1?key=AIzaSyBePxZHFeMq34uAxIzNDh1crl8p0TNmKU8&cx=20959f619f2f4480c&q=" + this.state.query)
    .then(response => response.json())
    .then(response => {
        this.setState({ results: response.items });
        console.log(response.items);
    });
  }

  render() {
    return(
      <div className='container animate-bottom'>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Dashboard</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center'}}>
          <p className="display-3" style={{fontSize: '50px'}}>Welcome {this.getCookie('fullname')}!</p>
        </div>
        <br></br>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary dash-nav" style={{backgroundColor: '#354a5a !important', borderRadius: '40px'}}>
          <div className="container-fluid">
            <input className="form-control me-sm-2" type="text" placeholder="Search" style={{borderRadius: '25px'}} onChange={this.inputHandler}/>
            <button className="btn btn-secondary my-2 my-sm-0 dash-btn" type="submit" style={{backgroundColor: '#354a5a !important', border: '0px'}}><i className="fa fa-search" aria-hidden="true" onClick={this.searchHandler}/></button>
          </div>
        </nav>
        {this.state.showList === true &&
        <List input={this.state.inputText} />
        }
        {/*<div className="jumbotron dashboard-header" style={{background: '#731F26', color: 'white !important'}}>
          <h1 className="display-3">Hello, {this.getCookie('fullname')}!</h1>
          <p className="lead">Welcome to the Case Medical, Inc. Viewbox application. Here you will find the new, advanced, and secure reporting system.</p>
          <hr className="my-4" />
          <p>If you need assistance with using the application, pleace click on the "Need Help?" button below to enter an IT ticket on the IT Portal.</p>
          <p className="lead">
            <a className="btn btn-primary btn-lg cm-btn" target="_blank" href="http://54.211.224.243/ITPortal/index.php/help-desk/submit-ticket" role="button">Need Help?</a>
          </p>
            </div>*/}
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-3">
            <div className="card text-white bg-light mb-6 dashboard-tiles">
              <div className="card-header"><strong>Time:</strong></div>
              <div className="card-body" style={{fontSize: '40px'}}>
                <Clock format={'h:mm:ss A'} ticking={true}/>
                <p style={{fontSize: '20px'}}>({Intl.DateTimeFormat().resolvedOptions().timeZone})</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card text-white bg-light mb-6 dashboard-tiles">
              <div className="card-header"><strong>Calendar:</strong></div>
              <div className="card-body">
                <Calendar/>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
          {/*<div className="col-lg-3">
            <div className="card text-white bg-light mb-3 dashboard-tiles" style={{maxWidth: '20rem'}}>
              <div className="card-header">Notifications:</div>
              <div className="card-body">
                <h4 className="card-title">Warning</h4>
                <p className="card-text">Network interruptions may be experienced as our ISP continues to make updates.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card text-white bg-light mb-3 dashboard-tiles" style={{maxWidth: '20rem'}}>
              <div className="card-header">Header</div>
              <div className="card-body">
                <h4 className="card-title">Primary card title</h4>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              </div>
            </div>
            </div>*/}
        </div>
        <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
    </div>
    );
  }
}

export default Dashboard;