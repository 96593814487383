import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import './prod.css';
import { Redirect } from 'react-router';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { RowHighlightPosition } from 'ag-grid-community';

class Labels extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.getInfo = this.getInfo.bind(this);
  this.state = {
    part: '',
    fileURL: '',
    random: 0,
    username: '',
    password: '',
    fullname: '',
    type: '',
    order: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    numPages: null,
    setNumPages: null,
    pageNumber: 1,
    setPageNumber: 1,
    lotno: '',
    recSrc: '',
    manSrc: '',
    solSrc: '',
    houseSrc: '',
    smallHouseSrc: '',
    lotSrc: '',
    lotid: '',
    partno: '',
    partnum: '',
    partdesc: '',
    custrev: '',
    desc: '',
    orderqty: '',
    shipqty: '',
    ifurev: '',
    gtin: '',
    memberof: '',
    access: '',
  }

  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);


    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Production')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChangeType = (event) => {
    this.setState({type: event.target.value});
    console.log("Label Type: " + this.state.type);
  }

  handleChangeLot = (event) => {
    this.setState({lotno: event.target.value});
    console.log("LOT NUMBER: " + this.state.lotno);
  }

  handleChangePart = (event) => {
    this.setState({partnum: event.target.value});
    console.log("PART NUMBER: " + this.state.partnum);
  }

  /*generatePDF = () => {
    const element = document.getElementById('report');
    var currentDate = new Date();
    var opt = {
              filename:     'CofC_' + currentDate.toLocaleDateString('en-US'),
              margin: [2,2,2,2]
            };
    // Choose the element and save the PDF for our user.
    html2pdf().set(opt).from(element).save();
  }*/

  resetIframe = () => {
    this.setState({random: this.state.random + 1});
}

getInfo = (e) => {
  e.preventDefault();

  this.setState({recSrc: 'http://174.32.189.27:8080/api/labels/recieving_label.php?lotNum=' + this.state.lotno});
    this.setState({manSrc: 'http://174.32.189.27:8080/api/labels/manufacturing_label.php?lotNum=' + this.state.lotno});
    this.setState({solSrc: 'http://174.32.189.27:8080/api/labels/solutions_label.php?lotNum=' + this.state.lotno});
    this.setState({houseSrc: 'http://174.32.189.27:8080/api/labels/in_house_label.php?lotNum=' + this.state.lotno});
    this.setState({smallHouseSrc: 'http://174.32.189.27:8080/api/labels/in_house_label_small.php?lotNum=' + this.state.lotno});
    this.setState({lotSrc: 'http://174.32.189.27:8080/api/labels/lot_label.php?lotNum=' + this.state.lotno + '&partNum=' + this.state.partnum});
}

_handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    this.setState({recSrc: 'http://174.32.189.27:8080/api/labels/recieving_label.php?lotNum=' + this.state.lotno});
    this.setState({manSrc: 'http://174.32.189.27:8080/api/labels/manufacturing_label.php?lotNum=' + this.state.lotno});
    this.setState({solSrc: 'http://174.32.189.27:8080/api/labels/solutions_label.php?lotNum=' + this.state.lotno});
    this.setState({houseSrc: 'http://174.32.189.27:8080/api/labels/in_house_label.php?lotNum=' + this.state.lotno});
    this.setState({smallHouseSrc: 'http://174.32.189.27:8080/api/labels/in_house_label_small.php?lotNum=' + this.state.lotno});
    this.setState({lotSrc: 'http://174.32.189.27:8080/api/labels/lot_label.php?lotNum=' + this.state.lotno + '&partNum=' + this.state.partnum});
  }
}


  printDocument = () => {
    const input = document.getElementById('report');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Labels");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }


  render() {

    return(
      <div className='row animate-bottom' style={{width: '100%', padding: '10px'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Labels</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
                <div className="col-2" style={{borderTopRightRadius: '25px', borderBottomRightRadius: '25px', backgroundColor: '#354a5a', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important'}}>
                <label style={{marginTop: '20px', color: 'white'}}>Select Label:</label>
                <select name="cofc" id="cofc" className="form-control check" style={{borderRadius: '5px'}} onChange={this.handleChangeType}>
                    <option value="">Select...</option>
                    <option value="man">Manufacturing</option>
                    <option value="rec">Recieving</option>
                    <option value="sol">Solutions</option>
                    <option value="lap">Lot and Part Numbers</option>
                    <option value="so">In House SO Label</option>
                    <option value="sso">In House SO Label (Small)</option>
                </select>
                <br></br>
                <label style={{marginTop: '20px', color: 'white'}}>Enter Text or Lot number:</label>
                <input type="text" required name="lot_num" id="lot_num" className="form-control check" style={{borderRadius: '5px'}} onKeyDown={this._handleKeyDown}  onChange={this.handleChangeLot}/>
                <br></br>
                {this.state.type == 'lap' ? <div><label style={{marginTop: '20px', color: 'white'}}>Enter Part Number:</label>
                <input type="text" required name="part_num" id="part_num" className="form-control check" style={{borderRadius: '5px'}} onKeyDown={this._handleKeyDown}  onChange={this.handleChangePart}/>
                <br></br></div>: ''}
                <button className="btn btn-secondary side-btn" style={{background: '#354a5a !important', border: '0px !important'}} onClick={this.getInfo}><i className="fa fa-search" aria-hidden="true"/>&nbsp;Search</button>
                {/*<br></br>
                <button className="btn btn-secondary side-btn" style={{background: '#354a5a !important', border: '0px !important'}} onClick={this.printDocument}><i className="fa fa-file-pdf-o" aria-hidden="true"/>&nbsp;Export to PDF</button>*/}
            </div>
            <div className="col-10" style={{height: '100%'}}>
            {this.state.type == '' ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%'}}><h3>Select type of label, enter the lot number or text, and click "Search".</h3></div> : '' }
            {this.state.type == 'man' ? <div id="report"><iframe style={{width: '100%', height: '100vh'}} src={this.state.manSrc}></iframe></div> : '' }
      {this.state.type == 'rec' ? <div id="report"><iframe style={{width: '100%', height: '100vh'}} src={this.state.recSrc}></iframe></div> : '' }
      {this.state.type == 'sol' ? <div id="report"><iframe style={{width: '100%', height: '100vh'}} src={this.state.solSrc}></iframe></div> : '' }
      {this.state.type == 'lap' ? <div id="report"><iframe style={{width: '100%', height: '100vh'}} src={this.state.lotSrc}></iframe></div> : '' }
        {this.state.type == 'so' ? <div id="report"><iframe style={{width: '100%', height: '100vh'}} src={this.state.houseSrc}></iframe></div> : '' }
        {this.state.type == 'sso' ? <div id="report"><iframe style={{width: '100%', height: '100vh'}} src={this.state.smallHouseSrc}></iframe></div> : '' }
            </div>
            <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
    </div>
    );
  }
}

export default Labels;