import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from 'react-modal';

class AnodizeParts extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.openModal = this.openModal.bind(this);
  this.closeModal = this.closeModal.bind(this);
  this.openEditModal = this.openEditModal.bind(this);
  this.closeEditModal = this.closeEditModal.bind(this);
  //this.onSelectionChanged = this.onSelectionChanged.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: [],
    modalIsOpen: false,
    setIsOpen: false,
    editModalIsOpen: false,
    editSetIsOpen: false,
    currentPart: '',
    currentRackArea: '',
    currentFingerArea: '',
    currentFingerQty: '',
    currentPartArea: '',
    currentAlloyType: '',
    access: '',
    fingerArea: '',
    fingerQty: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Anodizing')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }

    var access_token = this.getCookie('access_token');

    axios.get(`http://174.32.189.27:8080/api/anodize/anodize_parts.php`, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        console.log("Row Data: " + JSON.stringify(rowData));
      });
  }


  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    var access_token = this.getCookie('access_token');

    axios.get(`http://174.32.189.27:8080/api/anodize/anodize_parts.php`, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        console.log("Row Data: " + JSON.stringify(rowData));
      });
  };
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  addNewPart = (event) => {
    event.preventDefault();
    console.log("INFO TO POST: " + this.state.currentPart + "," + this.state.currentPartArea + "," + this.state.currentRackArea + "," + this.state.currentAlloyType);

    var bodyFormData = new FormData();
    bodyFormData.append('part_num', event.target.part_num.value);
    bodyFormData.append('part_area', event.target.part_area.value);
    bodyFormData.append('rack_area', event.target.rack_area.value);
    bodyFormData.append('finger_area', event.target.finger_area.value);
    bodyFormData.append('finger_qty', event.target.finger_qty.value);
    bodyFormData.append('alloy_type', event.target.alloy_type.value);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/anodize/add_part.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        window.location.reload(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.reload(false);
      });
  }

  editPart = (event) => {
    event.preventDefault();
    console.log("INFO TO UPDATE: " + this.state.currentPart + "," + this.state.currentPartArea + "," + this.state.currentRackArea + "," + this.state.currentAlloyType);

    var bodyFormData = new FormData();
    bodyFormData.append('part_num', event.target.part_num.value);
    bodyFormData.append('part_area', event.target.part_area.value);
    bodyFormData.append('rack_area', event.target.rack_area.value);
    bodyFormData.append('finger_area', event.target.finger_area.value);
    bodyFormData.append('finger_qty', event.target.finger_qty.value);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/anodize/edit_part.php?partID=" + event.target.part_num.value,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        window.location.reload(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.reload(false);
      });
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
        var date = new Date();
  var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
  var timeInput = date.toLocaleTimeString();

    var bodyFormData = new FormData();
    bodyFormData.append('username', this.getCookie('username'));
    bodyFormData.append('page', "Anodize Parts");
    bodyFormData.append('action', "access");
    bodyFormData.append('date', dateInput);
    bodyFormData.append('time', timeInput);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/access/insert.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  openModal = () => {
    this.setState({ setIsOpen: true });
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ setIsOpen: false });
    this.setState({ modalIsOpen: false });
  }
  openEditModal = () => {
    this.setState({ editSetIsOpen: true });
    this.setState({ editModalIsOpen: true });
  }

  closeEditModal = () => {
    this.setState({ editSetIsOpen: false });
    this.setState({ editModalIsOpen: false });
  }


  render() {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {zIndex: 10000},
      };

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
        <div className="row">
            <div className="col-10">
                <h3>Anodizing Parts</h3>
                <p>If you see any errors, click "Help" in the dropdown under your username.</p>
            </div>
            <div className="col-2">
                <button className="btn btn-success" onClick={this.openModal}><i className="fa fa-plus-circle" aria-hidden="true" />&nbsp;Add Part</button>
                <br></br>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    <button onClick={this.closeModal} className="btn btn-dark" style={{backgroundColor: 'transparent', color: 'black', border: 'none'}}>X close</button>
                    <form className="form-main" method="post" onSubmit={this.addNewPart}>
                        <div className="row">
                        <div className="col-md-12">
                            <h2 className="card-title mb-5">Add New Part<br /><small>Please enter part information and measurements.</small></h2>
                        </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Part #</label>
                              <input type="text" required name="part_num" className="form-control" onChange={(e) => {
                                        this.setState({ currentPart: e.target.value});
                                        localStorage.setItem("part_num", e.target.value)
                                      }}/>
                              </div>
                          </div>
                          <div className="col-md-3">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Part Area</label>
                              <input type="text" required name="part_area" className="form-control" onChange={(e) => {
                                        this.setState({ currentPartArea: e.target.value});
                                        localStorage.setItem("part_area", e.target.value)
                                      }}/>
                              </div>
                          </div>
                          <div className="col-md-3">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Rack Area</label>
                              <input type="text" required name="rack_area" className="form-control" onChange={(e) => {
                                        this.setState({ currentRackArea: e.target.value});
                                        localStorage.setItem("rack_area", e.target.value)
                                      }}/>
                              </div>
                          </div>
                          <div className="col-md-3">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Finger Area</label>
                              <input type="text" required name="finger_area" className="form-control" onChange={(e) => {
                                        this.setState({ currentFingerArea: e.target.value});
                                        localStorage.setItem("finger_area", e.target.value)
                                      }}/>
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Finger Qty</label>
                              <input type="text" required name="finger_qty" className="form-control" onChange={(e) => {
                                        this.setState({ currentFingerQty: e.target.value});
                                        localStorage.setItem("finger_qty", e.target.value)
                                      }}/>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Alloy Type</label>
                              <select required name="alloy_type" className="form-control" onChange={(e) => {
                                        this.setState({ currentAlloyType: e.target.value});
                                        localStorage.setItem("alloy_type", e.target.value)
                                      }}>
                                  <option value />
                                  <option value="6061">Titanium</option>
                                  <option value="5052">Aluminum</option>
                              </select>
                              </div>
                          </div>
                        </div>
                        <div className="form-group text-center mt-4">
                        <button type="submit" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info mx-3 py-2 px-4 switch">Submit</button>
                        </div>
                    </form>
                </Modal>
                <Modal
                    isOpen={this.state.editModalIsOpen}
                    onRequestClose={this.closeEditModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    <button onClick={this.closeEditModal} className="btn btn-dark" style={{backgroundColor: 'transparent', color: 'black', border: 'none'}}>X close</button>
                    <form className="form-main" onSubmit={this.editPart}>
                        <div className="row">
                        <div className="col-md-12">
                            <h2 className="card-title mb-5">Edit Part<br /><small>Please enter part information and measurements.</small></h2>
                        </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Part #</label>
                              <input type="text" required name="part_num" className="form-control" value={this.state.currentPart} disabled/>
                              </div>
                          </div>
                          <div className="col-md-4">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Part Area</label>
                              <input type="text" required name="part_area" className="form-control" defaultValue={this.state.currentPartArea}/>
                              </div>
                          </div>
                          <div className="col-md-4">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Rack Area</label>
                              <input type="text" required name="rack_area" className="form-control" defaultValue={this.state.currentRackArea}/>
                              </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Finger Area</label>
                              <input type="text" required name="finger_area" className="form-control" defaultValue={this.state.currentFingerArea}/>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Finger Qty</label>
                              <input type="text" required name="finger_qty" className="form-control" defaultValue={this.state.currentFingerQty}/>
                              </div>
                          </div>
                        </div>
                        <div className="form-group text-center mt-4">
                        <button type="submit" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info mx-3 py-2 px-4 switch">Submit</button>
                        </div>
                    </form>
                </Modal>
                </div>
            </div>
           <table class="table table-bordered" style={{color: 'black', backgroundColor: 'white'}}>
                <tbody>
                    <tr>
                        <th>Part Name</th>
                        <th>Part Area (Square inches)</th>
                        <th>Rack Area (Square feet)</th>
                        <th>Finger Area (Square inches)</th>
                        <th>Finger Qty</th>
                        <th>Alloy Type</th>
                        <th>Action</th>
                    </tr>
                    {this.state.rowData.map((info) => {
                           return <tr>
                                <td><strong>{info.partID}</strong></td>
                                <td><strong>{info.partArea}</strong></td>
                                <td><strong>{info.rackArea}</strong></td>
                                <td><strong>{info.fingerArea}</strong></td>
                                <td><strong>{info.fingerQty}</strong></td>
                                <td><strong>{info.alloy_type}</strong></td>
                                <td><button className="btn btn-success" onClick={() => {
                                      this.openEditModal();
                                      this.setState({ currentPart: info.partID});
                                      this.setState({ currentPartArea: info.partArea});
                                      this.setState({ currentRackArea: info.rackArea});
                                      this.setState({ currentFingerArea: info.fingerArea});
                                      this.setState({ currentFingerQty: info.fingerQty});
                                    }}>Edit</button>
                                </td>
                            </tr>
                        
                      })}
                    </tbody>
                    </table>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default AnodizeParts;