import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import './blank.css';
import { Redirect } from 'react-router';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

class BlankCofc extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    part: '',
    fileURL: '',
    random: 0,
    username: '',
    password: '',
    fullname: '',
    type: '',
    order: '',
    data:'',
    memberof: '',
    error: '',
    login: '',
    redirect: '',
    numPages: null,
    setNumPages: null,
    pageNumber: 1,
    setPageNumber: 1,
    address: '',
    blankSrc: 'http://174.32.189.27:8080/api/cofc/cofc/blank.php',
    testSrc: 'http://174.32.189.27:8080/api/cofc/cofc/Test.php',
    medSrc: '',
    strykSrc: '',
    depSrc: '',
    regSrc: '',
    delOrder: '',
    partNum: '',
    orderno: '',
    pono: '',
    partno: '',
    partrev: '',
    lotno: '',
    custrev: '',
    desc: '',
    orderqty: '',
    shipqty: '',
    ifurev: '',
    gtin: '',
    access: '',
  }

  
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Production')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }
  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChangeType = (event) => {
    this.setState({type: event.target.value});
    console.log("COFC Type: " + this.state.type);
  }

  handleChangeOrder = (event) => {
    this.setState({order: event.target.value});
    console.log("ORDER NUMBER: " + this.state.order);
  }

  handleChange = (event) => {
    this.setState({part: event.target.value});
    console.log("PART NUMBER: " + this.state.part);
  }

  /*generatePDF = () => {
    const element = document.getElementById('report');
    var currentDate = new Date();
    var opt = {
              filename:     'CofC_' + currentDate.toLocaleDateString('en-US'),
              margin: [2,2,2,2]
            };
    // Choose the element and save the PDF for our user.
    html2pdf().set(opt).from(element).save();
  }*/

  resetIframe = () => {
    this.setState({random: this.state.random + 1});
}

getInfo = (e) => {
  e.preventDefault();

  //var access_token = this.getCookie('access_token');

  //this.setState({loading: true});

  this.setState({regSrc: 'http://174.32.189.27:8080/api/cofc/cofc/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});
    this.setState({depSrc: 'http://174.32.189.27:8080/api/cofc/cofc/DePuy/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});
    this.setState({medSrc: 'http://174.32.189.27:8080/api/cofc/cofc/Medtronic/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});
    this.setState({strykSrc: 'http://174.32.189.27:8080/api/cofc/cofc/Stryker/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});

  axios.get(`http://174.32.189.27:8080/api/cofc/cofc.php?delOrder=` + this.state.order + `&partNum=` + this.state.part, {})
    .then(res => {
      this.setState({ address : res.data[0]['address'] });
        this.setState({ pono : res.data[0]['customer_po_number'] });
        this.setState({ orderno : res.data[0]['sales_order'] });
        this.setState({ partno : res.data[0]['product_number'] });
        this.setState({ partrev : res.data[0]['revision'] });
        this.setState({ lotno : res.data[0]['_lot_id'] });
        this.setState({ gtin : res.data[0]['udfref'] });
        this.setState({ desc : res.data[0]['product_desc'] });
        this.setState({ orderqty : res.data[0]['product_qty'] });
        this.setState({ shipqty : res.data[0]['qty_delivered'] });
        console.log("Row Data: " + JSON.stringify(res.data));
        this.setState({loading: false});
    });
}

_handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    this.setState({regSrc: 'http://174.32.189.27:8080/api/cofc/cofc/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});
    this.setState({depSrc: 'http://174.32.189.27:8080/api/cofc/cofc/DePuy/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});
    this.setState({medSrc: 'http://174.32.189.27:8080/api/cofc/cofc/Medtronic/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});
    this.setState({strykSrc: 'http://174.32.189.27:8080/api/cofc/cofc/Stryker/index.php?saleOrder=' + this.state.order + '&partNum=' + this.state.part});

  axios.get(`http://174.32.189.27:8080/api/cofc/cofc.php?delOrder=` + this.state.order + `&partNum=` + this.state.part, {})
    .then(res => {
      this.setState({ address : res.data[0]['address'] });
        this.setState({ pono : res.data[0]['customer_po_number'] });
        this.setState({ orderno : res.data[0]['sales_order'] });
        this.setState({ partno : res.data[0]['product_number'] });
        this.setState({ partrev : res.data[0]['revision'] });
        this.setState({ lotno : res.data[0]['_lot_id'] });
        this.setState({ gtin : res.data[0]['udfref'] });
        this.setState({ desc : res.data[0]['product_desc'] });
        this.setState({ orderqty : res.data[0]['product_qty'] });
        this.setState({ shipqty : res.data[0]['qty_delivered'] });
        console.log("Row Data: " + JSON.stringify(res.data));
        this.setState({loading: false});
    });
  }
}


  printDocument = () => {
    const input = document.getElementById('report');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "C of C");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }


  render() {

    return(
      <div className='row animate-bottom' style={{width: '100%', padding: '10px'}}>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
                <div className="col-2" style={{borderTopRightRadius: '25px', borderBottomRightRadius: '25px', backgroundColor: '#354a5a', boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important'}}>
                <label style={{marginTop: '20px', color: 'white'}}>Select Certificate:</label>
                <select name="cofc" id="cofc" className="form-control check" style={{borderRadius: '5px'}} onChange={this.handleChangeType}>
                    <option value="">Select...</option>
                    <option value="regular">Regular</option>
                    <option value="blank">Blank</option>
                    <option value="depuy">DePuy</option>
                    <option value="medtronic">Medtronic</option>
                    <option value="stryker">Stryker</option>
                    <option value="test">Test</option>
                </select>
                <br></br>
                <label style={{marginTop: '20px', color: 'white'}}>Enter Sales Order number:</label>
                <input type="text" required name="part_num" id="part_num" className="form-control check" style={{borderRadius: '5px'}} onKeyDown={this._handleKeyDown} onChange={this.handleChangeOrder}/>
                <br></br>
                <label style={{marginTop: '20px', color: 'white'}}>Enter Part number:</label>
                <input type="text" required name="part_num" id="part_num" className="form-control check" style={{borderRadius: '5px'}} onKeyDown={this._handleKeyDown} onChange={this.handleChange}/>
                <br></br>
                <button className="btn btn-secondary side-btn" style={{background: '#354a5a !important', border: '0px !important'}} onClick={this.getInfo}><i className="fa fa-search" aria-hidden="true"/>&nbsp;Search</button>
            </div>
            <div className="col-10" style={{height: '100%'}}>
            {this.state.type == '' ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%'}}><h3>Select type of certificate of compliance, enter the sales order number and part number and click "Search".</h3></div> : '' }
            {this.state.type == 'blank' ? <iframe style={{width: '100%', height: '100vh'}} src={this.state.blankSrc}></iframe> : '' }
            {this.state.type == 'test' ? <iframe style={{width: '100%', height: '100vh'}} src={this.state.testSrc}></iframe> : '' }
      {this.state.type == 'regular' ?<iframe style={{width: '100%', height: '100vh'}} src={this.state.regSrc}></iframe> : '' }
      {this.state.type == 'depuy' ? <iframe style={{width: '100%', height: '100vh'}} src={this.state.depSrc}></iframe> : '' }
        {this.state.type == 'stryker' ? <iframe style={{width: '100%', height: '100vh'}} src={this.state.strykSrc}></iframe> : '' }
        {this.state.type == 'medtronic' ? <iframe style={{width: '100%', height: '100vh'}} src={this.state.medSrc}></iframe> : '' }
        
            </div>
            <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
    </div>
    );
  }
}

export default BlankCofc;