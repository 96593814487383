import React from 'react';
import './footer.css';
import {Helmet} from "react-helmet";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
        year: ''
    }
  }

  componentDidMount(){
    this.setState({year : new Date().getFullYear()});
  }


  render() {
    return(
        <footer id="footer">
          <Helmet>
              <script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" type="text/javascript" />
              <script src="./js/googletranslate.js" type="text/javascript" />
            </Helmet>
        <div className="row">
          <div className="col-lg-12">
            <p>Copyright © <a className="footer-link" target="_blank" href="https://www.casemed.com/" style={{color: '#354a5a !important'}}rel="nofollow">Case Medical, Inc.</a> {this.state.year}. All Rights Reserved.</p>
            <div id="google_translate_element" style={{marginTop: '20px'}}></div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;