import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class QCReview extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: [],
    values: [],
    modalIsOpen: false,
    setIsOpen: false,
    operator: '',
    part_num: '',
    part_qty: '',
    lot_num: '',
    shop_order_num: '',
    anodize_thickness: '',
    line_num: '',
    tank_num: '',
    time_in: '',
    rack_qty: '',
    rack_type: '',
    temp: '',
    amps: '',
    thick: '',
    extra: '',
    fin: '',
    time_out: '',
    note: '',
    thickness: '',
    final_thickness: '',
    extra_time: '',
    log_ID: '',
    access: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }

    var access_token = this.getCookie('access_token');

      axios.get(`http://174.32.189.27:8080/api/anodize/anodize_entry.php?id=` + this.props.location.state.id, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        this.setState({ operator: res.data[0].operator });
        this.setState({ part_num: res.data[0].part_num });
        this.setState({ part_qty: res.data[0].part_quantity });
        this.setState({ rack_qty: res.data[0].rack_quantity });
        this.setState({ rack_type: res.data[0].rack_type });
        this.setState({ lot_num: res.data[0].lot_num });
        this.setState({ shop_order_num: res.data[0].shop_order_num });
        this.setState({ time_in: res.data[0].time_in_anodize });
        this.setState({ temp: res.data[0].anodize_temp });
        this.setState({ amps: res.data[0].amps });
        this.setState({ tank_num: res.data[0].tank_num });
        this.setState({ line_num: res.data[0].line_num });
        this.setState({ anodize_thickness: res.data[0].anodize_thickness });
        this.setState({ time_out: res.data[0].time_out });
        this.setState({ note: res.data[0].note });
        this.setState({ log_ID: res.data[0].log_ID });
        this.setState({ thickness: res.data[0].thickness });
        this.setState({ final_thickness: res.data[0].final_thickness });
        this.setState({ extra_time: res.data[0].extra_time });
        this.setState({ log_ID: res.data[0].log_ID });
        console.log("Row Data: " + JSON.stringify(rowData));
      });

  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');

        var date = new Date();
  var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
  var timeInput = date.toLocaleTimeString();

    var bodyFormData = new FormData();
    bodyFormData.append('username', this.getCookie('username'));
    bodyFormData.append('page', "Anodize QC Review");
    bodyFormData.append('action', "access");
    bodyFormData.append('date', dateInput);
    bodyFormData.append('time', timeInput);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/access/insert.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
      
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  addStageQc = (event) => {
    event.preventDefault();
    //console.log("INFO TO POST: " + this.state.currentPart + "," + this.state.currentPartArea + "," + this.state.currentRackArea + "," + this.state.currentAlloyType);

    var bodyFormData = new FormData();
    bodyFormData.append('qc_sign', event.target.qc_sign.value);
    bodyFormData.append('qc_pass', event.target.qc_pass.value);
    bodyFormData.append('qc_rework', event.target.qc_rework.value);
    bodyFormData.append('qc_scrap', event.target.qc_scrap.value);
    bodyFormData.append('comment', event.target.comment.value);
    bodyFormData.append('split_order', event.target.split_order.value);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/anodize/submit_qc.php?log_id=" + this.state.log_ID,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        window.location.replace(window.location.origin + '/anodizelog');
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.reload(false);
      });
  }

  render() {

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
              <div className="container">
                <form className="form-main" method="post" action="submit_qc_review.php">
        <h4 className="card-title my-3">Anodizing Stage One</h4>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Operator</label>
              <input type="text" required className="form-control" name="operator" id="operator" disabled defaultValue={this.state.operator} />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Part #</label>
              <input type="text" required className="form-control" name="part_num" autoComplete="off" defaultValue={this.state.part_num} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Parts Quantity</label>
              <input type="number" min={1} required name="part_quantity" className="form-control" autoComplete="off" defaultValue={this.state.part_qty} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Lot #</label>
              <input type="text" required className="form-control" name="lot_num" autoComplete="off" defaultValue={this.state.lot_num} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Shop Order #</label>
              <input type="text" required className="form-control" name="shop_order_num" autoComplete="off" defaultValue={this.state.shop_order_num} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Line #</label>
              <input type="text" required className="form-control" name="line_num" autoComplete="off" defaultValue={this.state.line_num} disabled />
            </div>
          </div>
        </div>    
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Tank #</label>
              <input type="number" required className="form-control" name="tank_num" autoComplete="off" defaultValue={this.state.tank_num} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Time In</label>
              <input type="time" required name="time_in_anodize" className="form-control" autoComplete="off" defaultValue={this.state.time_in} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Rack Quantity</label>
              <input type="number" min={1} required name="rack_quantity" className="form-control" autoComplete="off" defaultValue={this.state.rack_qty} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Rack Type</label>
              <input type="text" required name="rack_type" className="form-control" autoComplete="off" defaultValue={this.state.rack_type} disabled />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Temperature (C)</label>
              <input type="text" className="form-control" name="anodizing_temp" required disabled autoComplete="off" defaultValue={this.state.temp} />
            </div>
          </div>
        </div>
        <h4 className="card-title my-3">Anodizing Stage Two</h4>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Thickness</label>
              <input type="text" required className="form-control" name="anodize_thickness" autoComplete="off" defaultValue={this.state.thickness} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Extra Time</label>
              <input type="text" required name="extra_time" className="form-control" autoComplete="off" defaultValue={this.state.extra_time} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Final Thickness</label>
              <input type="text" required className="form-control" name="anodize_final_thickness" autoComplete="off" defaultValue={this.state.final_thickness} disabled />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Time Out</label>
              <input type="time" required className="form-control" name="time_out" autoComplete="off" defaultValue={this.state.time_out} disabled />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Amps <i className="fa fa-exclamation-circle" style={{color: 'red'}} /></label>
              <input type="text" required className="form-control" name="amps" autoComplete="off" defaultValue={this.state.amps} disabled />
            </div>
          </div>
        </div> 
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Notes</label>
              <textarea className="form-control" name="note" autoComplete="off" disabled defaultValue={this.state.note} />
            </div>
          </div>
        </div>   
      </form>
      <hr />
      <form className="form-main" method="post" onSubmit={this.addStageQc}>
        <h4 className="card-title my-3">Quality Control Review</h4>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">QC Sign</label>
              <input type="text" required className="form-control" name="qc_sign" readOnly defaultValue={this.getCookie('fullname')} id="operator" />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Pass</label>
              <input type="text" required className="form-control" name="qc_pass" autoComplete="off" />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Rework</label>
              <input type="text" required name="qc_rework" className="form-control" autoComplete="off" />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Scrap</label>
              <input type="text" required className="form-control" name="qc_scrap" autoComplete="off" />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Use As Is</label>
              <input type="text" required className="form-control" name="split_order" autoComplete="off" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Comments</label>
              <input type="text" required className="form-control" name="comment" autoComplete="off" />
            </div>
          </div>
        </div>
        <div className="form-group text-center mt-4">
          <button type="submit" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info mx-3 py-2 px-4">Submit</button>
          <Link to={{pathname: '/anodizelog'}}><button type="reset" href="/index.php" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-danger mx-3 py-2 px-4">Cancel</button></Link>                              
        </div>                      
        </form>
        </div>
           <div style={{textAlign: 'center', marginTop: '20px'}}>
        <Footer></Footer>
            </div>
        </div>
    );
  }
}

export default QCReview;