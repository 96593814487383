import React from 'react';
import Dashnav from '../../../components/dashnav/dashnav';
import Footer from '../../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class ShipKarlstorz extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var access_token = this.getCookie('access_token');

    this.interval = setInterval(this.getData, 60000);
    this.getData();

  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  getData = () => {
    axios.get(`http://174.32.189.27:8080/api/shippingpriority/karlstorz.php`, {})
    .then(res => {
      const rowData = res.data;
      this.setState({ rowData });
      console.log("Row Data: " + JSON.stringify(rowData));
    });
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
    var date = new Date();
    var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    var timeInput = date.toLocaleTimeString();
  
      var bodyFormData = new FormData();
      bodyFormData.append('username', this.getCookie('username'));
      bodyFormData.append('page', "Shipping Priority KarlStorz");
      bodyFormData.append('action', "access");
      bodyFormData.append('date', dateInput);
      bodyFormData.append('time', timeInput);
  
      axios({
        method: "post",
        url: "http://174.32.189.27:8080/api/access/insert.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  render() {

     // set background colour on even rows again, this looks bad, should be using CSS classes
     let prevOrder = '99999';
     let counter = 0;
     let style = '';

     const getRowStyle = params => {
         if(params.data.sales_order_number != prevOrder){
             counter++;
         }
         if(counter == 12){
             counter = 1;
         }
         switch (counter) {
             case 1:
               style = 'rgba(166, 229, 215, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 2:
               style = 'rgba(250, 226, 162, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 3:
               style = 'rgba(251, 203, 193, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 4:
               style = 'rgba(198, 234, 237, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 5:
               style = 'rgba(255, 235, 189, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 6:
               style = 'rgba(179, 227, 157, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case  6:
               style = 'rgba(179, 227, 157, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 7:
               style = 'rgba(221, 216, 246, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 8:
               style = 'rgba(232, 219, 166, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 9:
               style = 'rgba(249, 157, 141, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 10:
               style = 'rgba(154, 182, 155, 0.6)';
               prevOrder = params.data.sales_order_number;
               break;
             case 11:
               style = 'rgba(206, 206, 182, 0.6)';
               prevOrder = params.data.sales_order_number;
               
           }
             return { background: style };
     };

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - Shipping Priority Karlstorz</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
        <Dashnav></Dashnav>
        <div style={{textAlign: 'center'}}>
            <h3>Shipping Priority - Karlstorz</h3>
            </div>
            <div className="ag-theme-alpine" style={{height: '86vh', width: '100%;'}}>
               <AgGridReact defaultColDef={{
                flex: 1,
                minWidth: 100,
                enableValue: true,
                enableRowGroup: true,
                enablePivot: true,
                sortable: true,
                filter: true,
                resizable: true,
                cellStyle: {fontWeight: 'bold'}
              }} 
              sideBar={true} 
              rowData={this.state.rowData} 
              rowHeight={50} 
              pagination={true}
              getRowStyle={getRowStyle}
              >
                   <AgGridColumn field="customer_name" headerName="Customer Name" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="customer_po_number" headerName="Customer PO Number" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="sales_order_number" headerName="Sales Order Number" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="status" headerName="Order Status" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="product_number" headerName="Part Number" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="order_date" headerName="Order Date" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="deadline" headerName="Deadline" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="product_qty" headerName="Order Quantity" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="qty_delivered" headerName="Shipped Quantity" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="rem_qty" headerName="Remaining Quantity" sortable={ true } filter={ true }></AgGridColumn>
                   <AgGridColumn field="_make_number" headerName="Manufacturing Order" sortable={ true } filter={ true }></AgGridColumn>
               </AgGridReact>
           </div>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default ShipKarlstorz;