import React from 'react';
import Dashnav from '../../components/dashnav/dashnav';
import Footer from '../../components/footer/footer';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { Bars } from  'react-loader-spinner';
import Modal from 'react-modal';
import 'datatables.net';

class AnodizeLog extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.openModal = this.openModal.bind(this);
  this.closeModal = this.closeModal.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
    rowData: [],
    partData: [],
    ampData: [],
    values: [],
    modalIsOpen: false,
    setIsOpen: false,
    part_num: '',
    rack_qty: 0,
    part_qty: 0,
    rack_type: '',
    access: '',
    phase: '',
    loading: '',
    line_num: '',
  }
}

componentDidMount() {
    window.addEventListener('load', this.checkLogin);

    var memberof = this.getCookie('memberof').toString();
    this.setState({memberof: memberof});
    console.log("MEMBER OF: " + this.state.memberof);

    if(memberof.includes('QC') || memberof.includes('Administrator') || memberof.includes('Engineering') || memberof.includes('Anodizing')){
      this.setState({access: true});
    }
    else{
      this.setState({access: false});
    }

    localStorage.setItem("amps", 0);
    localStorage.setItem("part_num", "");
    localStorage.setItem("part_qty", 0);
    localStorage.setItem("rack_qty", 0);
    localStorage.setItem("rack_type", "");

    var access_token = this.getCookie('access_token');

    axios.get(`http://174.32.189.27:8080/api/anodize/getparts.php`, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const partData = res.data;
        this.setState({ partData });
        console.log("Part Data: " + JSON.stringify(partData));
          //$('#example').DataTable();
      });

  }
  
  componentWillUnmount() { 
   window.removeEventListener('load', this.checkLogin);
  }

  handleChangePhase = (event) => {
    this.setState({phase: event.target.value});
    console.log("PHASE: " + this.state.phase);
  }


  getAnodizeLog = (event) => {

    event.preventDefault();

    this.setState({loading: true});

    var access_token = this.getCookie('access_token');

    axios.get(`http://174.32.189.27:8080/api/anodize/anodize_log.php?phase=` + this.state.phase, {headers: {'Authorization': 'Bearer ' + access_token}})
      .then(res => {
        const rowData = res.data;
        this.setState({ rowData });
        console.log("Row Data: " + JSON.stringify(rowData));
        this.setState({loading: false});
      });
  }

  showTable = () => {
    try {
      return this.state.rowData.map((info) => {
        if (info.phase == "stage_two"){
           return <tr className="alert-warning" style={{color: "white !important"}}>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.qc_sign}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.timestamp}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.operator}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.part_num}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.part_quantity}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.lot_num}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.shop_order_num}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.time_in_anodize}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.time_out}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.amps}</Link></td>
            </tr>
        }
        else if (info.phase == "stage_one"){
            return <tr className="alert-danger" style={{color: "white !important"}}>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}><i className="fa fa-times-circle" aria-hidden="true" style={{color: "white !important"}}></i></Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.qc_sign}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.timestamp}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.operator}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.part_num}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.part_quantity}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.lot_num}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.shop_order_num}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.time_in_anodize}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.time_out}</Link></td>
                <td><Link to={{pathname: '/stagetwo',state: { id: info.id }}}>{info.amps}</Link></td>
             </tr>
        }
        else if (info.phase == "stage_qc"){
            return <tr  className="alert-info" style={{color: "white !important"}}>
                 <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}><i className="fa fa-pencil" aria-hidden="true"></i></Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.qc_sign}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.timestamp}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.operator}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.part_num}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.part_quantity}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.lot_num}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.shop_order_num}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.time_in_anodize}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.time_out}</Link></td>
                <td><Link to={{pathname: '/qcreview',state: { id: info.id }}}>{info.amps}</Link></td>
             </tr>
        }
        else if (info.phase == "stage_final"){
            return <tr className="alert-success" style={{color: "white !important"}}>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}><i className="fa fa-check-circle" aria-hidden="true"></i></Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.qc_sign}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.timestamp}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.operator}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.part_num}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.part_quantity}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.lot_num}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.shop_order_num}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.time_in_anodize}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.time_out}</Link></td>
                 <td><Link to={{pathname: '/qcedit',state: { id: info.id }}}>{info.amps}</Link></td>
             </tr>
        }
        else {
            return <tr className="alert-danger" style={{color: "white !important"}}>
                 <td><i className="fa fa-times-circle" aria-hidden="true" style={{color: "white !important"}}></i></td>
                 <td style={{color: "white !important"}}>{info.qc_sign}</td>
                 <td style={{color: "white !important"}}>{info.timestamp}</td>
                 <td style={{color: "white !important"}}>{info.operator}</td>
                 <td style={{color: "white !important"}}>{info.part_num}</td>
                 <td style={{color: "white !important"}}>{info.part_quantity}</td>
                 <td style={{color: "white !important"}}>{info.lot_num}</td>
                 <td style={{color: "white !important"}}>{info.shop_order_num}</td>
                 <td style={{color: "white !important"}}>{info.time_in_anodize}</td>
                 <td style={{color: "white !important"}}>{info.time_out}</td>
                 <td style={{color: "white !important"}}>{info.amps}</td>
             </tr>
        }
    })
    } catch (e) {
      alert(e.message);
    }
  };

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');

        var date = new Date();
        var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
        var timeInput = date.toLocaleTimeString();

          var bodyFormData = new FormData();
          bodyFormData.append('username', this.getCookie('username'));
          bodyFormData.append('page', "Anodize Log");
          bodyFormData.append('action', "access");
          bodyFormData.append('date', dateInput);
          bodyFormData.append('time', timeInput);

          axios({
            method: "post",
            url: "http://174.32.189.27:8080/api/access/insert.php",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              //handle success
              console.log(response);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  calculateAmps = () => {

    var d = new Date();
    d.setTime(d.getTime() + (24*60*60*1000));
    var expires = "expires="+ d.toUTCString();

   axios.get(`http://174.32.189.27:8080/api/anodize/calculate_amps.php?pn=` + localStorage.getItem("part_num") + `&pq=` + localStorage.getItem("part_qty") + `&rq=` + localStorage.getItem("rack_qty") + `&line=` + localStorage.getItem("line_num"))
      .then(res => {
        const ampData = res.data;
        this.setState({ ampData });
        console.log("Amp Data: " + JSON.stringify(ampData));
        localStorage.setItem("amps", ampData);
      });
  }

  addNewLog = (event) => {
    event.preventDefault();
    //console.log("INFO TO POST: " + this.state.currentPart + "," + this.state.currentPartArea + "," + this.state.currentRackArea + "," + this.state.currentAlloyType);

    var bodyFormData = new FormData();
    bodyFormData.append('operator', event.target.operator.value);
    bodyFormData.append('part_num', event.target.part_num.value);
    bodyFormData.append('part_quantity', event.target.part_quantity.value);
    bodyFormData.append('rack_quantity', event.target.rack_quantity.value);
    bodyFormData.append('amps', event.target.amps.value);
    bodyFormData.append('rack_type', event.target.rack_type.value);
    bodyFormData.append('tank_num', event.target.tank_num.value);
    bodyFormData.append('line_num', event.target.line_num.value);
    bodyFormData.append('time_in_anodize', event.target.time_in_anodize.value);
    bodyFormData.append('lot_num', event.target.lot_num.value);
    bodyFormData.append('shop_order_num', event.target.shop_order_num.value);
    bodyFormData.append('anodize_temp', event.target.anodize_temp.value);
    bodyFormData.append('anodize_thickness', event.target.anodize_thickness.value);
    bodyFormData.append('extra_time', event.target.extra_time.value);
    bodyFormData.append('anodize_final_thickness', event.target.anodize_final_thickness.value);
    bodyFormData.append('note', event.target.note.value);
    bodyFormData.append('time_out', event.target.time_out.value);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/anodize/submit_stageone.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
        window.location.reload(false);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        window.location.reload(false);
      });
  }

  handleChangePart = (event) => {
    this.setState({part_num: event.target.value});
    console.log("PART NUMBER: " + this.state.part_num);
    localStorage.setItem("part_num", event.target.value);
    this.calculateAmps();
  }

  handleChangePartQty = (event) => {
    this.setState({part_qty: event.target.value});
    console.log("PART QTY: " + this.state.part_qty);
    localStorage.setItem("part_qty", event.target.value);
    this.calculateAmps();
  }

  handleChangeRackQty = (event) => {
    this.setState({rack_qty: event.target.value});
    console.log("RACK QTY: " + this.state.rack_qty);
    localStorage.setItem("rack_qty", event.target.value);
    this.calculateAmps();
  }

  handleChangeRackType = (event) => {
    this.setState({rack_type: event.target.value});
    console.log("RACK TYPE: " + this.state.rack_type);
    localStorage.setItem("rack_type", event.target.value);
    this.calculateAmps();
  }

  handleChangeLine = (event) => {
    this.setState({line_num: event.target.value});
    console.log("LINE #: " + this.state.rack_type);
    localStorage.setItem("line_num", event.target.value);
    this.calculateAmps();
  }

  openModal = () => {
    this.setState({ setIsOpen: true });
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ setIsOpen: false });
    this.setState({ modalIsOpen: false });
  }

  render() {

    let style = '';

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {zIndex: 10000},
      };

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%', height: '86vh'}}>
        <Helmet>
          
    <title>Case Medical, Inc. | Viewbox - Anodizing Log</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
              {this.state.access === false &&
              <Redirect to="/dashboard" />
              }
        <Dashnav></Dashnav>
        <div className="row">
            <div className="col-10">
                <h3>Log Records</h3>
                <h4>Please choose a record to complete by clicking a card.</h4>
                <p>Please, select the phase in anodizing you would like to see the logs of first and click "Search".</p>
                <p>If you see any errors, click "Help" in the dropdown under your username.</p>
                        <form className="d-flex" onSubmit={this.getAnodizeLog} style={{marginBottom: '25px'}}>
                            <h4>Phase:</h4>&nbsp;
                            <select className="form-control me-sm-2 round-date" id="phaseSel" name="phaseSel" onChange={this.handleChangePhase} style={{width: '15%'}}>
                              <option></option>
                              <option value="stage_one">Incomplete Record</option>
                              <option value="stage_two">Anodizing</option>
                              <option value="stage_qc">Quality Review</option>
                              <option value="stage_final">Valid Record</option>
                            </select>&nbsp;
                            <button className="btn btn-dark" type="submit" style={{backgroundColor: 'transparent', color: 'black', border: 'none'}}><i className="fa fa-search" aria-hidden="true"/>&nbsp;Search</button>
                        </form>
                        
            {this.state.loading == true ?
            <div style={{justifyContent: 'center'}}>
             <Bars
             height="100"
             width="100"
             color='grey'
             ariaLabel='loading'
             style={{justifyContent: 'center'}}
           />
           </div>
             : ''}
            </div>
            <div className="col-2">
                <button className="btn btn-success" onClick={this.openModal}><i className="fa fa-plus-circle" aria-hidden="true" />&nbsp;Create Record</button>
                <table style={{width: '100%', marginTop: '20px'}}>
        <tbody><tr>
            <th style={{width: '30%'}} className="text-center">Icon</th>
            <th style={{width: '70%'}}>Stage</th>
          </tr>
          <tr>
            <td className="text-center">
              <i className="fa fa-times-circle" style={{color: '#ab343f'}} />
            </td>
            <td>
              <small>Incomplete Record</small>
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <i className="fa fa-exclamation-triangle" style={{color: '#b79020'}} />
            </td>
            <td>
              <small>Anodizing</small>
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <i className="fa fa-pencil" style={{color: '#2784e8'}} />
            </td>
            <td>
              <small>Quality Review</small>
            </td>
          </tr>
          <tr>
            <td className="text-center">
              <i className="fa fa-check-circle" style={{color: '#2b9a44'}} />
            </td>
            <td>
              <small>Valid Record</small>
            </td>
          </tr>
        </tbody></table>
                <br></br>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    <button onClick={this.closeModal} className="btn btn-dark" style={{backgroundColor: 'transparent', color: 'black', border: 'none'}}>X close</button>
                    <form className="form-main" method="post" onSubmit={this.addNewLog}>
                        <div className="row">
                        <div className="col-md-8">
                            <h2 className="card-title mb-5">Anodizing Log<br /><small>Please enter the required information.</small></h2>
                        </div>
                        <div className="col-md-4 text-right">
                            {/* <h2>Amps: <span id="amps" class="badge badge-warning">0</span></h2> */}
                            <h2>Amps: <input readOnly className="form-control check" style={{background: 'yellow'}} name="amps" type="text" id="amps_input" value={this.state.ampData}/></h2>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Operator</label>
                            <input type="text" required className="form-control" name="operator" id="operator" readOnly value={this.getCookie('username')} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Part #</label>
                            <select required className="form-control check" id="part_num" name="part_num" value={this.state.part_num}  onChange={this.handleChangePart} autoComplete="off">
                            <option></option>
                                {this.state.partData.map((info) => {
                                    return <option value={info.part}>{info.part}</option>
                                })}
                                
                            </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Parts Quantity</label>
                            <input type="number" min="1" required name="part_quantity" id="part_quantity" value={this.state.part_qty} onChange={this.handleChangePartQty} className="form-control check" />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Rack Quantity</label>
                            <input type="number" min="1" required name="rack_quantity" id="rack_quantity" value={this.state.rack_qty} onChange={this.handleChangeRackQty} className="form-control check" />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Rack Type</label>
                            <select name="rack_type" className="form-control check" id="rack_type" value={this.state.rack_type} onChange={this.handleChangeRackType} required>
                                <option value />
                                <option value="T">T</option>
                                <option value="A">A</option>
                            </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Line #</label>
                            <select required className="form-control check" id="line_num" name="line_num" value={this.state.line_num}  onChange={this.handleChangeLine} autoComplete="off">
                              <option></option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Tank #</label>
                            <input type="text" className="form-control switch" name="tank_num"/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Time In</label>
                            <input type="time" name="time_in_anodize" className="form-control switch"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Lot #</label>
                            <input type="text" className="form-control switch" name="lot_num"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Shop Order #</label>
                            <input type="text" className="form-control switch" name="shop_order_num"/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Temperature (C)</label>
                            <input type="number" className="form-control switch" name="anodize_temp"/>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Thickness</label>
                            <input type="text" className="form-control switch" name="anodize_thickness"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Extra Time</label>
                            <input type="text" name="extra_time" className="form-control pending"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Final Thickness</label>
                            <input type="text" name="anodize_final_thickness" className="form-control pending"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Time Out</label>
                            <input type="time" name="time_out" className="form-control switch"/>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Notes</label>
                                <textarea name="note" className="form-control"></textarea>
                              </div>
                          </div>
                        </div>
                        <div className="form-group text-center mt-4">
                        <button type="submit" style={{display: 'inline-block', fontSize: '20px'}} className="h1 btn btn-info mx-3 py-2 px-4 switch">Submit</button>
                        </div>
                    </form>
                </Modal>
                </div>
            </div>
           <table class="table table-bordered" id="example" style={{color: 'black', backgroundColor: 'white'}}>
                <tbody>
                    <tr>
                        <th>Stage</th>
                        <th>QC Sign</th>
                        <th>Date</th>
                        <th>Operator</th>
                        <th>Part #</th>
                        <th>Part QTY</th>
                        <th>Lot #</th>
                        <th>Shop Order #</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Amps</th>
                    </tr>
                    {this.showTable()}
                </tbody>
            </table>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default AnodizeLog;