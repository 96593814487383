import React from 'react';
import './navbar.css';

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      logout: false,
    }
  }


  render() {
    return(
        <div className="fixed-top">
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
          <a href="/" className="navbar-brand"><img className="production-logo" src="./img/case-med-logo.png" style={{height: '40px'}} /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarColor02">
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;